import { instance } from '@/utils';

export default {
  saveChartData({ commit }, payload) {
    commit('loading', true);
    return instance.post('/api/zinschart/', payload);
  },
  fetchZinsChartData({ commit }, id) {
    commit('loading', true);
    return instance.get(`/api/zc/${id}/`);
  },
  async fetchInterestChartData({ commit }, payload) {
    commit('loading', true);
    const { params, fields, chartType } = payload;
    const token = localStorage.getItem('token');
    instance.defaults.headers.common['Authorization'] = `Token ${token}`;
    try {
      if (chartType === 'base') {
        const res = await instance.post('/api/graphql', {
          query: `
        {
          zinschart(${params}) {
            data {
              bottomChart {
                datum
                zins1
                zins2
                zins3
                ${fields.join('\n')}
              }
            }
          }
        }
      `,
        });
        if (res?.data?.data?.zinschart?.data?.bottomChart?.length) {
          if (chartType === 'base') {
            commit(
              'setBaseChartData',
              res.data.data.zinschart.data.bottomChart
            );
          }
        }
        commit('loading', false);
        return res?.data?.data?.zinschart?.data?.bottomChart?.length
          ? res.data.data.zinschart.data.bottomChart
          : [];
      } else {
        const res = await instance.post('/api/graphql', {
          query: `
        {
          spezialchart(${params}) {
            param {
              zeitraumVon,
              zeitraumBis,
              produkte
            }
            data {
              chart {
               datum
                zins1
                zins2
                zins3
              }
            }
          }
        }
      `,
        });
        commit('loading', false);
        return res?.data?.data?.spezialchart?.data?.chart || [];
      }
    } catch (e) {
      commit('loading', false);
      commit('error', true);
    }
  },
};
