








































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapMutations, MutationMethod } from 'vuex';

import TopBar from '@/components/TopBar/TopBar.vue';
import {
  BASE_BLACK_COLOR,
  BASE_COLOR,
  NAVIGATION_CONFIG,
} from '@/utils/constants';

@Component({
  components: { TopBar },
  computed: {
    ...mapGetters({
      error: 'reports/error',
      wizardError: 'wizard/error',
      maxColumns: 'wizard/maxColumns',
      updateExistingReport: 'reports/updateExistingReport',
      permissions: 'auth/getPermissions',
    }),
  },
  methods: {
    ...mapMutations({
      resetError: 'reports/resetError',
      resetWizardError: 'wizard/resetError',
      clearSingleReport: 'reports/clearSingleReport',
      clearDatenaboData: 'datenabo/clearDatenaboData',
    }),
  },
})
export default class Navigation extends Vue {
  @Prop() drawer!: Boolean;
  @Prop() mini!: Boolean;

  color: String = BASE_COLOR;
  blackColor = BASE_BLACK_COLOR;
  config: Object = NAVIGATION_CONFIG;
  panel = null;
  error!: Boolean;
  wizardError!: Boolean;
  clearSingleReport!: MutationMethod;
  resetError!: MutationMethod;
  resetWizardError!: MutationMethod;
  permissions!: Array<string>;

  @Watch('$route', { immediate: true }) set() {
    if (
      (this.$router as any).history.current.path === '/' ||
      (this.$router as any).history.current.path === '/chart-generator' ||
      (this.$router as any).history.current.path === '/banking-profile'
    ) {
      this.panel = null;
    }
    if ((this.$router as any).history.current.meta.hasOwnProperty('panel')) {
      if (!this.drawer) {
        this.$emit('change-drawer', { drawer: !this.drawer, mini: !this.mini });
      }
      if (!this.panel) {
        this.panel = this.currentPanel();
      }
    }
    if (this.error && !Object.keys(this.$router.currentRoute.params).length) {
      this.resetError();
    }
    if (
      this.wizardError &&
      !Object.keys(this.$router.currentRoute.params).length
    ) {
      this.resetWizardError();
    }
    if (
      !Object.keys(this.$router.currentRoute.params).length &&
      !this.$router.currentRoute.path.includes('banking-profile')
    ) {
      this.clearSingleReport();
    }
  }
  @Watch('drawer') changePanel() {
    if (!this.drawer && this.panel !== null) {
      this.panel = null;
    }
    if (
      this.drawer &&
      this.panel === null &&
      (this.$router as any).history.current.meta.hasOwnProperty('panel')
    ) {
      this.panel = this.currentPanel();
    }
  }

  validationNotPanels(permission) {
    if (Array.isArray(permission)) {
      const search = this.permissions.filter((item) =>
        permission.includes(item)
      );
      return search.length >= 3;
    }
    return this.permissions.includes(permission);
  }
  validate(panel) {
    const allPermissions = panel.items
      .map((item) => item.permission)
      .filter((i) => i);
    if (allPermissions.length) {
      return allPermissions.some((it) => this.permissions.includes(it));
    } else {
      return this.permissions.includes(panel.permission);
    }
  }
  handleChangeDrawer() {
    this.$emit('change-drawer', { drawer: !this.drawer, mini: !this.mini });
  }

  currentPanel() {
    const permissions = JSON.parse(localStorage.getItem('permissions') || '');
    const arr = (this.config as any).map((item) => {
      const allPermissions = item.items
        .map((item) => item?.permission)
        .filter((i) => i);
      if (allPermissions.length) {
        return allPermissions.some((it) => permissions.includes(it)) && item;
      }
      return permissions.includes(item.permission) && item;
    });
    const indexes = {
      1: 0,
      2: 1,
      5: 2,
      6: 3,
    };
    return indexes[
      arr.findIndex(
        (item) =>
          item?.title === (this.$router as any).history.current.meta.panel
      )
    ];
  }
  get valueMini() {
    return this.mini;
  }
  set valueMini(val) {}
  get valueDrawer() {
    return this.drawer;
  }
  set valueDrawer(val) {}
  handleChangePanel(e) {
    if (typeof e === 'undefined') {
      this.panel = null;
    } else {
      this.panel = e;
    }
    if (!this.drawer && this.panel !== null) {
      this.$emit('change-drawer', { drawer: !this.drawer, mini: !this.mini });
    }
  }
}
