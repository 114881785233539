import router from '@/router';
import { defaultState } from './index';

function updateReports(allReports, data) {
  return allReports.map((report) => {
    if (report.slug === data.slug) {
      return {
        ...report,
        note: data.note || '',
        tags: data?.tags || [],
        title: data.title,
        config: data.config,
      };
    }
    return report;
  });
}
function sort(state, { row, icon }) {
  const copyState = { ...state.result };
  const products = copyState.products || [];
  const index = products.findIndex((item) => item.id === row.id);
  icon === 'mdi-chevron-down'
    ? products.splice(index + 1, 0, products.splice(index, 1)[0])
    : products.splice(index - 1, 0, products.splice(index, 1)[0]);
  return Object.assign(copyState, { products });
}
function modifyResult(state, payload) {
  state.result = Object.assign({}, state.result, payload);
}

function modifyReports(reports, payload) {
  const { rows, ...rest } = payload;
  const items = rows || [];
  const result = Object.assign(rest, { rows: items });
  return reports.map((item) =>
    item.key === result.key ? { ...item, ...result } : item
  );
}
function setReportType(payload) {
  if (router.currentRoute.params.producttype) {
    return payload.REPORT_TYPE[
      router.currentRoute.params.producttype.toUpperCase()
    ];
  }
  if (router.currentRoute.name) {
    return payload.REPORT_TYPE[router.currentRoute.name.toUpperCase()];
  }
}
export default {
  cancelControllers(state) {
    if (state.abortControllers) {
      state.abortControllers.forEach((controller) => {
        controller.abort();
      });
    }
    state.abortControllers = null;
    state.allReportsTagesgeld = [];
    state.allReportsFestgeld = [];
    state.allReportsBaugeld = [];
  },
  clearReports(state, payload) {
    // the payload contains state keys that must be reset to their original state.
    payload.reports.forEach((key) => {
      state[key] = [];
    });
    payload.loading.forEach((key) => {
      state[key] = false;
    });
  },
  addControllers(state, controllers) {
    state.abortControllers = controllers;
  },
  clearControllers(state) {
    state.abortControllers = null;
  },
  disabled(state, payload) {
    state.isDisabledPicker = payload;
  },
  loadingPreview(state, payload) {
    state.loadingPreview = payload;
  },
  loadingAllReportsBaugeld(state, payload) {
    state.loadingAllReportsBaugeld = payload;
  },
  loadingAllReportsFestgeld(state, payload) {
    state.loadingAllReportsFestgeld = payload;
  },
  loadingAllReportsTagesgeld(state, payload) {
    state.loadingAllReportsTagesgeld = payload;
  },
  loading(state, payload) {
    state.loading = payload;
  },
  uploadReportSuccess(state, payload) {
    state.uploadReport = payload;
  },
  mapSkeleton(state, payload) {
    if (payload.report_type === '2') {
      state.allReportsFestgeld = payload.result;
    }
    if (payload.report_type === '1') {
      state.allReportsTagesgeld = payload.result;
    }
    if (payload.report_type === '3') {
      state.allReportsBaugeld = payload.result;
    }
  },
  fetchAllReportsSuccess(state, payload) {
    if (payload.report_type === '3') {
      state.allReportsBaugeld = modifyReports(
        state.allReportsBaugeld,
        payload.payload
      );
      state.updatedReportsBaugeld = false;
    }
    if (payload.report_type === '2') {
      state.allReportsFestgeld = modifyReports(
        state.allReportsFestgeld,
        payload.payload
      );
      state.updatedReportsFestgeld = false;
    }
    if (payload.report_type === '1') {
      state.allReportsTagesgeld = modifyReports(
        state.allReportsTagesgeld,
        payload.payload
      );
      state.updatedReportsTagesgeld = false;
    }
  },
  fetchDeleteReportSuccess(state, payload) {
    if (payload.product_type === '1') {
      state.allReportsTagesgeld = state.allReportsTagesgeld.filter(
        (item) => item.slug !== payload.slug
      );
    }
    if (payload.product_type === '2') {
      state.allReportsFestgeld = state.allReportsFestgeld.filter(
        (item) => item.slug !== payload.slug
      );
    }
    if (payload.product_type === '3') {
      state.allReportsBaugeld = state.allReportsBaugeld.filter(
        (item) => item.slug !== payload.slug
      );
    }
  },
  fetchPreviewReportSuccess(state, payload) {
    const productType = {
      1: 'tagesgeld',
      2: 'festgeld',
      3: 'baugeld',
    };
    state.links = {
      report: `/${productType[+state.report_type]}/report/${payload.slug}`,
      dashboard: `/${productType[+state.report_type]}`,
    };
    const allBanks =
      payload.config?.allBanks?.filter((bank) => bank.includes('_')) || [];
    state.previewReport = payload;
    state.filtersForEditing = payload.config;
    state.dataTable = { ...state.dataTable, allBanks };
  },
  setPreviewReport(state, payload) {
    const { headers, rows } = payload;
    state.dataTable = Object.assign(
      state.dataTable,
      { headers: headers, rows: rows },
      {}
    );
  },
  setUpdateReport(state, payload) {
    const allBanks =
      payload.config?.allBanks?.filter((bank) => bank.includes('_')) || [];
    state.dataTable = { ...state.dataTable, allBanks };
    state.filtersForEditing = payload.config;
    state.newReport = {
      title: payload.title,
      uid: payload.uid,
      slug: payload.slug,
      report_type: payload.report_type,
    };
  },
  setUpdateExistingReport(state, payload) {
    const { config, ...rest } = payload;
    const allBanks =
      config?.allBanks?.filter((bank) => bank.includes('_')) || [];
    state.filtersForEditing = Object.assign(
      config,
      { tags: rest.tags ? rest.tags : [], note: rest.note ? rest.note : null },
      {}
    );
    state.dataTable = { ...state.dataTable, allBanks };
    state.updateExistingReport = {
      title: payload.title,
      uid: payload.uid,
      slug: payload.slug,
      report_type: payload.report_type,
    };
  },
  updateReportSuccess(state, payload) {
    if (payload.productType && payload.slug) {
      state.links = {
        report: `/${payload.productType}/report/${payload.slug}`,
        dashboard: `/${payload.productType}`,
      };
    }
    state.newReport = Object.assign(state.newReport, payload.payload);
    state.singleReport = Object.assign(state.newReport, payload.payload);
    state.editReport = payload.editReport;
    state.previewReport = {};
    state.updatedReportsTagesgeld = payload.productType === 'tagesgeld';
    state.updatedReportsFestgeld = payload.productType === 'festgeld';
    state.updatedReportsBaugeld = payload.productType === 'baugeld';
  },
  updateExistingReportSuccess(state, payload) {
    if (payload.productType && payload.slug) {
      state.links = {
        report: `/${payload.productType}/report/${payload.slug}`,
        dashboard: `/${payload.productType}`,
      };
    }
    state.singleReport = Object.assign(
      state.updateExistingReport,
      payload.payload
    );
    state.error = false;
    state.loading = false;
    state.editReport = payload.editReport;
    state.previewReport = {};
    state.updatedReportsTagesgeld = payload.productType === 'tagesgeld';
    state.updatedReportsFestgeld = payload.productType === 'festgeld';
    state.updatedReportsBaugeld = payload.productType === 'baugeld';
  },
  setFetchSingleReport(state, payload) {
    const { report_type, ...rest } = payload;
    state.singleReport = rest;
    state.report_type = report_type;
  },
  fetchSingleReportSuccess(state, payload) {
    state.isDisabledPicker = false;
    const { rows, ...rest } = payload;
    state.uploadedReports = {
      tagesgeld: false,
      festgeld: false,
      baugeld: false,
    };
    state.singleReport = {
      ...state.singleReport,
      ...rest,
      rows: rows || [],
    };
    state.loading = false;
    state.uploadReport = true;
  },
  setEditReport(state, payload) {
    const { config, ...res } = payload;
    const { allBanks, ...rest } = config;
    state.disabledModalFields = {
      product_tilgung_manual: false,
    };
    if (allBanks?.length) {
      Object.assign(rest, { anbieterAuswahl: allBanks }, {});
      state.result = {
        ...state.result,
        ...rest,
        selection_title: res.title,
        tags: res.tags ? res.tags : [],
        note: res.note ? res.note : null,
      };
      state.filtersForEditing = {
        ...rest,
        tags: res.tags ? res.tags : [],
        note: res.note ? res.note : null,
        selection_title: res.title,
      };
    } else {
      state.result = {
        ...state.result,
        ...config,
        tags: res.tags ? res.tags : [],
        note: res.note ? res.note : null,
        selection_title: res.title,
      };
      state.filtersForEditing = {
        ...config,
        tags: res.tags ? res.tags : [],
        note: res.note ? res.note : null,
        selection_title: res.title,
      };
    }

    state.editReport = true;
    state.previewReport = {};
    state.updateExistingReport = {
      title: payload.title,
      uid: payload.uid,
      slug: payload.slug,
      report_type: payload.report_type,
    };
    state.newReport = {};
    state.loading = false;
  },
  saveSelectedCheckboxes(state, payload) {
    return modifyResult(state, payload);
  },
  setSelectedValue(state, payload) {
    return modifyResult(state, payload);
  },
  error(state, payload) {
    state.error = payload;
  },
  clearSingleReport(state) {
    state.singleReport = {};
    state.uploadReport = false;
    state.disabledModalFields = {
      product_tilgung_manual: false,
    };
  },
  clearResult(state) {
    state.disabledModalFields = {
      product_tilgung_manual: false,
    };
    state.links = {};
    state.uploadedReports = {
      tagesgeld: false,
      festgeld: false,
      baugeld: false,
    };
    state.allReportsFestgeld = [];
    state.allReportsBaugeld = [];
    state.allReportsTagesgeld = [];
    state.result = {
      banken_vermittlerangebote: 0,
      products: [],
    };
    state.filtersForEditing = {};
    state.sumMeta = 0;
    state.loading = false;
    state.editReport = false;
    state.newReport = {};
    state.singleReport = {};
    state.error = false;
    state.dataTable = {};
    state.previewReport = {};
    state.updateExistingReport = {};
  },
  resetError(state) {
    state.error = false;
  },
  setReportType(state, payload) {
    state.report_type = setReportType(payload);
    state.loading = false;
  },
  saveSumMeta(state, payload) {
    state.sumMeta = payload;
  },
  setProductsFilters(state, payload) {
    state.result = {
      ...state.result,
      products: state.result.products.concat([{ ...payload }]),
    };
  },
  deleteProduct(state, payload) {
    state.result = {
      ...state.result,
      products: [...state.result.products].filter(
        (item) => item.id !== payload
      ),
    };
  },
  changeExistingProduct(state, payload) {
    const newArr = [...state.result.products].map((i) => {
      if (i.id === payload.id) return { ...payload };
      return { ...i };
    });
    state.result = {
      ...state.result,
      products: newArr,
    };
  },
  sortProducts(state, payload) {
    return sort(state, payload);
  },
  resetSingleReport(state) {
    state.singleReport = {};
  },
  resetState(state) {
    Object.assign(state, defaultState(), {});
  },
  setUploadedReports(state, payload) {
    const [[key, val]] = Object.entries(payload);
    state.uploadedReports[key] = val;
  },
  setClassDisabled(state, payload) {
    state.disabledModalFields = { ...state.disabledModalFields, ...payload };
  },
  modifySelectionDialog(state, payload) {
    state.isSelectionDialog = payload;
  },
  updateReport(state, data) {
    state.singleReport = {
      ...data,
      headers: state.singleReport.headers || data.headers,
      rows: state.singleReport.rows || data.rows,
      meta: state.singleReport.meta || data.meta,
    };
    state.uploadReport = true;
    switch (data.report_type) {
      case 1:
        return (state.allReportsTagesgeld = updateReports(
          state.allReportsTagesgeld,
          data
        ));
      case 2:
        return (state.allReportsFestgeld = updateReports(
          state.allReportsFestgeld,
          data
        ));
      case 3:
        return (state.allReportsBaugeld = updateReports(
          state.allReportsBaugeld,
          data
        ));
      default:
        return state;
    }
  },
  setSnackBarData(state, payload) {
    state.snackbarData = {
      ...state.snackbarData,
      ...{
        text: payload.text,
        show: payload.show,
        timeout: payload.timeout,
        color: payload?.color || '',
        dark: payload?.dark || true,
      },
    };
  },
  setDashboardTop(state, payload) {
    state.dashboardTop = payload;
  },
};
