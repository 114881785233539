import axios from 'axios';

import router from '../../router';
import { authInstance, instance, supersetInstance } from '../../utils';

export default {
  login({ commit }, data) {
    commit('loading', true);
    return authInstance.post('/api/public/sessions/login', data);
  },
  loginSuperSet() {
    const loginInfo = {
      password: 'uCAzqVn9ag9HqK2sfod',
      provider: 'db',
      refresh: false,
      username: 'admin',
    };
    const guestInfo = {
      user: {
        username: 'admin',
        first_name: 'Superset',
        last_name: 'Admin',
      },
      resources: [
        {
          type: 'dashboard',
          id: 'dd5b7539-4c74-494d-b7a7-f20c591390f3',
        },
      ],
      rls: [],
    };
    return supersetInstance
      .post('/api/v1/security/login', loginInfo)
      .then((loginResponse) => {
        supersetInstance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${loginResponse.data.access_token}`;

        return supersetInstance
          .post('/api/v1/security/guest_token/', guestInfo)
          .then((response) => response.data.token);
      });
  },
  authCheck({ commit }, payload) {
    const token = localStorage.getItem('token');
    commit('loading', true);
    authInstance
      .put('/api/public/sessions/authorize', payload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          const data = JSON.parse(res?.data?.[0] || '');
          if (Object.keys(data)) {
            const [[, val]] = Object.entries(data);
            localStorage.setItem('expiresAt', JSON.stringify(val));
          }
          axios.defaults.headers.common['Authorization'] = `Token ${token}`;
          instance.defaults.headers.common['Authorization'] = `Token ${token}`;
          commit('setAuthorized', true);
          commit('loading', false);
        }
      })
      .catch((e) => {
        commit('loading', false);
        if (
          e?.response?.status !== 401 &&
          !window.location.pathname.includes('/login')
        ) {
          commit('clearStore');
          localStorage.clear();
          return router.push({ path: '/login' }).catch(() => {});
        }
      });
  },
  forgotPassword({ commit }, payload) {
    return instance.put('/api/public/sessions/password-forgotten', payload);
  },
  resetPassword({ commit }, payload) {
    const token = localStorage.getItem('token');
    axios
      .post('reset-password', payload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        if (res) {
          return router.push({ path: '/login' });
        }
      })
      .catch((e) => {
        console.log(e.response);
      });
  },
  logOutSession({ commit }) {
    localStorage.clear();
    commit('clearStore');
  },
};
