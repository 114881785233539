










































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

import TopBar from '@/components/TopBar/TopBar.vue';
import FootBar from '@/components/FooterBar/FooterBar.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import FooterBar from '@/components/FooterBar/FooterBar.vue';

import { NAMES_PUBLIC_PAGES, PUBLIC_PAGES } from '@/utils/constants';
import { startTimer } from '@/utils/helpers';

@Component({
  components: { TopBar, FootBar, Navigation, FooterBar },
  computed: {
    ...mapGetters({
      allProjects: 'projects/getAllProjects',
      isAuthorized: 'auth/isAuthorized',
      authLoading: 'auth/getLoading',
    }),
  },
  methods: {
    ...mapActions({
      authCheck: 'auth/authCheck',
    }),
  },
})
export default class App extends Vue {
  drawer: Boolean = true;
  mini: Boolean = false;
  isAuthorized!: Boolean;
  currentPath = this.$router.currentRoute.fullPath;
  authCheck!: ({ page }) => Promise<Object>;

  mainContentPages: Array<string> = [
    '/:producttype/wizard/:id',
    '/:producttype/wizard',
    '',
  ];
  scrollPages = [
    'bankingProfileReport',
    'bankingProfileNewReport',
    'wizardEditReport',
    'wizard',
    'report',
  ];
  publicPages: string[] = PUBLIC_PAGES;

  get validatePath() {
    //eslint-disable-next-line
    return this.$router.currentRoute.matched?.[0] ? this.mainContentPages.includes(this.$router.currentRoute.matched[0].path) : false;
  }
  @Watch('isAuthorized') setTimer(val) {
    if (val) {
      const expiresAt = localStorage.getItem('expiresAt') || '';
      if (expiresAt) {
        const expire = JSON.parse(expiresAt);
        const expiresAtTime = Number(
          String(new Date(expire.split('+')[0]).getTime()).slice(0, 10)
        );

        const currentTime = Number(String(Date.now()).slice(0, 10));
        const expireTime = (expiresAtTime - currentTime) * 1000;
        startTimer(expireTime, this.$router.currentRoute.fullPath);
      }
    }
  }
  @Watch('$route') async checkAuthorizedAndSetPath() {
    if (
      !this.isAuthorized &&
      !(NAMES_PUBLIC_PAGES as any).includes(this.$router.currentRoute.name)
    ) {
      await this.authCheck({ page: this.$router.currentRoute.fullPath });
    }
    if (this.$router.currentRoute.name === 'rate-chart') {
      this.currentPath = '/rate-chart';
    } else {
      this.currentPath = this.$router.currentRoute.fullPath;
    }
  }

  handleChangeDrawer(val) {
    this.drawer = val.drawer;
    this.mini = val.mini;
  }

  get authorized() {
    return process.env.VUE_APP_IS_DEVELOPMENT === 'true'
      ? true
      : this.isAuthorized;
  }
}
