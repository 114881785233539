export const SETTINGS = {
  gridLinesColor: 'rgb(169,169,169)',
  euroZeichen: 'EUR',
  prozentZeichen: '%',
  datepicker: {
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    dayNames: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    today: new Date(),
    minDate: new Date(2010, 0, 1),
    maxDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
  },
};

export const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 20,
      bottom: 20,
    },
  },
  animations: false,
  scales: {
    x: {
      id: 'x-axis-0',
      display: true,
      beginAtZero: true,
      grid: {
        drawTicks: false,
        borderDash: [2, 4],
        color: SETTINGS.gridLinesColor,
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: window.innerWidth / 100,
        padding: 10,
        maxRotation: 0,
        minRotation: 0,
        beginAtZero: true,
      },
    },
    y: {
      beginAtZero: false,
      grid: {
        tickMarkLength: 10,
        borderDash: [3, 3],
        color: SETTINGS.gridLinesColor,
      },
      ticks: {
        padding: 10,
        callback: function (value, index, values) {
          const arr = values.map((val) => val.value);
          const min = Math.min(...arr);
          this.min = min - 0.01;
          return `${value.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
          })} ${SETTINGS.prozentZeichen}`;
        },
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      maxWidth: 10,
      fullSize: false,
      labels: {
        color: '#181818',
        font: {
          weight: 'bold',
          size: 14,
        },
      },
    },
  },
};

export const FORM_PARAMS_TAGESGELD = {
  bankname1: '',
  bankname2: '',
  bankname3: '',
  bankname4: '',
  bankname5: '',
  anlagebetrag: 10000,
  zeitraum: 2,
  zeitraumVon: null,
  zeitraumBis: null,
  kundenkreis: 0,
  vermittlerangebote: { value: 0, selected: false },
};

export const FORM_PARAMS_FESTGELD = {
  bankname1: '',
  bankname2: '',
  bankname3: '',
  bankname4: '',
  bankname5: '',
  zeitraum: 2,
  zeitraumVon: null,
  zeitraumBis: null,
  kundenkreis: 0,
  vermittlerangebote: { value: 0, selected: false },
  anlagebetrag: 50000,
  anlagedauer: 1,
};

export const FORM_PARAMS_BAUGELD = {
  bankname1: '',
  bankname2: '',
  bankname3: '',
  bankname4: '',
  bankname5: '',
  zeitraum: 2,
  zeitraumVon: null,
  zeitraumBis: null,
  sollzinsbindung: 10,
  beleihung: 60,
};

export const URLS = {
  4: process.env.VUE_APP_WEBAPI_ROOT,
  5: process.env.VUE_APP_WEBAPI_FESTGELD,
  6: process.env.VUE_APP_WEBAPI_BAUGELD,
};

export const PRODUCT_TYPES = {
  'wettbewerb-tagesgeld': 4,
  'wettbewerb-festgeld': 5,
  'wettbewerb-baugeld': 6,
};

export const TYPES = {
  4: 'tagesgeld',
  5: 'festgeld',
  6: 'baugeld',
};

export const ALL_REPORTS = {
  4: 'allReportsTagesgeld',
  5: 'allReportsFestgeld',
  6: 'allReportsBaugeld',
};

export const BELEIHUNG_TAGS_OPTIONS = [
  { text: '60%', value: 60 },
  { text: '80%', value: 80 },
  { text: '90%', value: 90 },
];

export const SOLLZINSBINDUNG_TAGS_OPTIONS = [
  { text: '5 Jahre', value: 5 },
  { text: '10 Jahre', value: 10 },
  { text: '15 Jahre', value: 15 },
  { text: '20 Jahre', value: 20 },
];
export const WETTBEWERB_PRODUCT_TYPES = {
  'wettbewerb-baugeld': 'mortgage.competitionData',
  'wettbewerb-tagesgeld': 'callMoney.competitionData',
  'wettbewerb-festgeld': 'timeDeposits.competitionData',
};
