const de = {
  general: {
    title: 'Titel',
    note: 'Notiz',
    actionBtn: {
      overview: 'übersicht',
      resetFilters: 'filter zurücksetzen',
      save: 'speichern',
      cancel: 'abbrechen',
      createReport: 'Report erstellen',
      createEvaluation: 'Auswertung erstellen',
      shortTerm: 'Kurzfristig',
      longTerm: 'Langfristig',
    },
    warning: {
      alert: 'Vorsicht',
    },
  },
  common: {
    addNewReportCard: {
      text: 'Neue Auswertung erstellen',
    },
    dataIterator: {
      groupHeadline: {
        latest: 'Ihre neuesten Auswertungen:',
        autoUpdate: 'Ihre laufenden Markt-Trackings, täglich aktualisiert:',
        other: 'Ihre gespeicherten Markt-Auswertungen',
      },
      noText: 'Erstellen Sie Ihre erste Auswertung',
      cardActions: {
        open: 'Öffnen',
      },
    },
  },
  German: 'Deutsch',
  English: 'Englisch',
  header: {
    bankingPortal: 'Der ganze Markt auf einen Blick.',
  },
  subHeader: {
    lastUpdated: 'Letzte Aktualisierung',
  },
  footer: {
    contact: 'Kontakt',
    imprint: 'Impressum',
    privacy: 'Datenschutz',
    phone: 'Telefon',
    fax: 'Telefax',
  },
  sidebar: {
    actionBtn: {
      zoom: 'Verkleinern',
    },
  },
  liveData: {
    generalTable: {
      noOfferTagesgeld: 'Kein Tagesgeld-Angebot',
      noOfferFestgeld: 'Kein Festgeld-Angebot',
    },
    title: 'Live-Daten',
    explanations:
      'Hier sehen Sie Ihre zuletzt verwendeten Auswertungen in\n' +
      'chronologischer Reihenfolge. Sie können neue Auswertungen\n' +
      'erstellen, oder bereits vorhandene anpassen, exportieren und\n' +
      'duplizieren. Recherchieren Sie tagesaktuelle Zinsen und\n' +
      'Konditionen, speichern Sie wichtige Momentaufnahmen oder richten\n' +
      'Sie ein regelmäßiges Tracking der Produkte Ihrer wichtigsten\n' +
      'Mitbewerber ein.',
  },
  bankingProfile: {
    title: 'Neue Anbieter-Auswertung anlegen',
    headline: 'Anbieterprofile',
    explanationText: 'Bitte geben Sie den Titel und den Notiz des Berichts ein',
    selectColumns: 'spalten auswählen',
    closeColumnSelect: 'spaltenauswählen schliessen',
    update: 'aktualisieren',
    selectAll: 'Alle',
    timePeriod: 'Zeitraum auswählen',
    region: 'Region auswählen',
    keyAreas: 'Schwerpunkte',
    activity: 'Aktivität',
    filter: 'Anbieter filtern',
    dataFilter: 'Daten filtern',
    description: 'Kurzprofil',
    table: {
      loadingText: 'Laden von items...',
      saveXLS: 'XLS Download',
      explanationText: 'Laden Sie die Ansicht als Excel Datei',
      selectedAdditionalHeadersLabel: 'Spalten einblenden',
      filter: {
        comparison: 'Vergleich',
        value: 'Wert',
        select: 'Wählen',
        booleanText: 'Ja/Nein',
        fields: 'Auswahl',
        regionLabel: 'Land suchen',
        larger: 'Größer',
        smaller: 'Kleiner',
        equal: 'Gleich',
        regionSelection: 'Land wählen',
      },
      headers: {
        provider: 'Anbieter',
        locations: 'Standorte',
        keyAreas: 'Geschäftsfelder',
        totalCapitalRatio: 'Gesamtkapitalquote',
        returnOnEquity: 'Eigenkapitalrendite',
        bankRatings: 'Ratings',
        headOffice: 'Hauptsitz',
        depositInsurance: 'Einlagensicherung',
        totalCapitalRatioCountry: 'Gesamtkapitalquote \n Land',
        totalCapitalRatioAverage: 'Gesamtkapitalquote \n durchschnittlich',
        totalAssets: 'Bilanzsumme',
        foundationLocation: 'Gründungsort',
        shareholder: 'Gesellschafter',
        subsidiaries: 'Tochtergesellschaften',
        stockExchangeListed: 'Börsennotiert',
        employees: 'Mitarbeiter',
        branchOffices: 'Filialen',
        coreStory: 'Kerngeschäft',
        mortgageVolume: 'Hypothekenvolumen',
        coreCapitalRatio: 'Kernkapitalquote',
        marketCapitalization: 'Marktkapitalisierung',
        equity: 'Eigenkapital',
        leverageRatio: 'Leverage Ratio',
        ratingParentCompany: 'Rating \n Muttergesellschaft',
        date: 'Datum',
        foundationYear: 'Gründungsjahr',
        returnEquityAverage: 'Durchschnittliche Eigenkapitalrendite',
        creditVolume: 'Kreditvolumen',
        investmentVolume: 'Anlagevolumen',
        commissionIncome: 'Provisionseinnahmen',
        profit: 'Gewinn',
        savingsDeposits: 'Spareinlagen',
        otherliabilities: 'Andere Verbindlichkeiten',
        totalPayables: 'Summe der Verbindlichkeiten',
        lienOnRealProperty: 'Grundpfandrecht',
        receivablesFromBanks: 'Forderungen an Kreditinstitute',
        receivablesCustomers: 'Forderungen an Kunden',
        commissionExpenses: 'Provisionsaufwendungen',
        netCommissionIncome: 'Provisionsertrag',
        commissionSurplus: 'Provisionsüberschuss',
      },
    },
  },
  chartGenerator: {
    criteria: 'Kriterien',
    tab2Text: 'Spezial-Grafiken',
    colors: 'Farben',
    color: 'Farbe',
    specialChartHousePriceDescription: {
      hp_hypothekenzins_index_hp_darlehenhoehe:
        'Die verwendeten Zinsen (80% Finanzierung) sind die Durchschnittszinsen pro' +
        ' Quartal (Zinsbindung 10 Jahre fest) aus der FMH-Datenbank. Die errechnete Kredithöhe unterstellt eine' +
        ' Finanzierungsdauer von 30 Jahren bei gleichbleibendem Zinssatz.',
      hp_darlehenhoehe_hp_einkommen:
        'Das verwendete Nettoeinkommen basiert auf den Zahlen des Statistischen Bundesamtes ' +
        'vom Febr. 2023. Die verwendeten Zinsen (80% Finanzierung) sind die Durchschnittszinsen pro Quartal (Zinsbindung ' +
        '10 Jahre fest) aus der FMH-Datenbank. Die errechnete Kredithöhe unterstellt eine Finanzierungsdauer von 30 Jahren' +
        ' bei gleichbleibendem Zinssatz.',
      hp_darlehenhoehe_hp_preis_neubau_haus_hp_preis_neubau_etw:
        'Die errechnete Kredithöhe unterstellt eine' +
        ' Finanzierungsdauer von 30 Jahren bei gleichbleibendem Zinssatz. Das zugrunde gelegte Nettoeinkommen basiert' +
        ' auf den Zahlen des Statistischen Bundesamtes vom Febr. 2023 für einen Doppelverdienerhaushalt. Die verwendeten' +
        ' Zinsen (80% Finanzierung) sind die Durchschnittszinsen pro Quartal (Zinsbindung 10 Jahre fest) aus der ' +
        'FMH-Datenbank. Die Haus- und ETW-Preise (140 und 100 qm) beruhen auf den Zahlen von „empirica“ vom Dezember ' +
        '2022 und sind jeweils Quartalsmittelwerte für Neubauten im ganzen Bundesgebiet.',
      hp_einkommen_hp_preis_neubau_haus:
        'Das zugrunde gelegte Nettoeinkommen basiert auf den Zahlen des Statistischen ' +
        'Bundesamtes vom Febr. 2023 für einen Doppelverdienerhaushalt. Die Haus-Preise für 140 qm Wohnfläche beruhen auf ' +
        'den Zahlen von „empirica“ vom Dezember 2022 und sind jeweils Quartalsmittelwerte für Neubauten im ganzen' +
        ' Bundesgebiet.',
    },
  },
  singleReport: {
    dateSelection: {
      label:
        'Sie haben die Möglichkeit, Konditionen anhand von zwei Zeitpunkten zu vergleichen. \n Beginnen Sie damit,' +
        ' das Datum auszuwählen, das weiter in der Vergangenheit liegt.\n Wenn Sie Echtzeitdaten sehen möchten, klicken' +
        ' Sie einfach auf',
      button: 'Live-Daten laden',
    },
  },
  dashboard: {
    newHere:
      'Nutzen Sie unsere innovative SaaS-Lösung und gestalten Sie Ihre Marktbeobachtung effizienter' +
      ' und einfacher. Verschaffen Sie sich einen umfassenden Überblick über die neuesten Trends und Entwicklungen, ' +
      'damit Sie stets bestens informiert sind und schnell auf Veränderungen reagieren können.',
    topAnbieter: {
      tagesgeldTop5: 'Tagesgeld Top 5',
      festgeldTop5: 'Festgeld Top 5',
      rang: 'Rang',
      zins: 'Zins',
      competitor: 'Wettbewerber',
      '2y': '2j',
      '5y': '5j',
      '10y': '10j',
      '3m': '3m',
      '6m': '6m',
      '12m': '12m',
      noData: 'Keine Daten vorhanden',
    },
  },
  wizard: {
    progressBar: {
      'Musterkunden erstellen': 'Musterkunden erstellen',
      'Wettbewerber auswählen': 'Wettbewerber auswählen',
      'Angebote filtern': 'Angebote filtern',
      'Produkte filtern': 'Angebote filtern',
      'Anbieter auswählen': 'Wettbewerber auswählen',
      'Banken Auswahl': 'Wettbewerber auswählen',
      Selektion: 'Berechnungsgrundlage einstellen',
      Felderauswahl: 'Bedingungen festlegen',
      Vorschau: 'Vorschau',
      Speichern: 'Speichern',
      Fertig: 'Fertig',
      'Filter Auswahl': 'Angebote filtern',
      Spaltenauswahl: 'Kriterien festlegen',
      Laufzeiten: 'Anlagedauer auswählen',
    },
  },
  warnings: {
    zinsChartWarning: 'Bitte ein Produkt auswählen',
    demoUserDeletingReport:
      'Das Löschen des Demo-Berichts ist als Demo-Benutzer nicht erlaubt',
    demoUserEditingReport:
      'Die Bearbeitung des Demo-Berichts ist als Demo-Benutzer nicht erlaubt',
  },
};

export default de;
