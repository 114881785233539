import axios from 'axios';

import {
  INTEREST_CREDIT_TAGESGELD,
  INTEREST_CALCULATION,
  BACKEND_URL,
  AUTH_API,
  SUPERSET_BACKEND_URL,
  NAMES_PUBLIC_PAGES,
} from './constants';
import { store } from '../store';
import router from '../router';

export const instance = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: false,
});
export const authInstance = axios.create({
  baseURL: AUTH_API,
  withCredentials: false,
});
export const supersetInstance = axios.create({
  baseURL: SUPERSET_BACKEND_URL,
  withCredentials: false,
});

authInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      !NAMES_PUBLIC_PAGES.includes(router.currentRoute.name)
    ) {
      store.commit('auth/clearStore');
      store.commit('specialSubscribers/resetState');
      store.commit('param/resetState');
      store.commit('wizard/resetState');
      store.commit('reports/resetState');
      store.commit('bankingProfile/resetState');
      localStorage.clear();
      router.push('/login').catch(() => {});
    } else {
      return Promise.reject(error);
    }
  }
);
