export default {
  error(state) {
    return state.error;
  },
  allReportsFestgeld(state) {
    return state.allReportsFestgeld;
  },
  allReportsTagesgeld(state) {
    return state.allReportsTagesgeld;
  },
  allReportsBaugeld(state) {
    return state.allReportsBaugeld;
  },
  loading(state) {
    return state.loading;
  },
  loadingPreview(state) {
    return state.loadingPreview;
  },
  singleReport(state) {
    return state.singleReport;
  },
  newReport(state) {
    return state.newReport;
  },
  editReport(state) {
    return state.editReport;
  },
  previewReport(state) {
    return state.previewReport;
  },
  updatedReportsTagesgeld(state) {
    return state.updatedReportsTagesgeld;
  },
  updatedReportsFestgeld(state) {
    return state.updatedReportsFestgeld;
  },
  updatedReportsBaugeld(state) {
    return state.updatedReportsBaugeld;
  },
  result(state) {
    return state.result;
  },
  dataTable(state) {
    return state.dataTable;
  },
  report_type(state) {
    return state.report_type;
  },
  componentsState(state) {
    return state.componentsState;
  },
  is_temporary(state) {
    return state.is_temporary;
  },
  uploadReport(state) {
    return state.uploadReport;
  },
  updateExistingReport(state) {
    return state.updateExistingReport;
  },
  demoTable(state) {
    return state.demoTable;
  },
  sumMeta(state) {
    return state.sumMeta;
  },
  filtersForEditing(state) {
    return state.filtersForEditing;
  },
  loadingReportsBaugeld(state) {
    return state.loadingAllReportsBaugeld;
  },
  loadingReportsFestgeld(state) {
    return state.loadingAllReportsFestgeld;
  },
  loadingReportsTagesgeld(state) {
    return state.loadingAllReportsTagesgeld;
  },
  getUploadedReports(state) {
    return state.uploadedReports;
  },
  getDisabledModalFields(state) {
    return state.disabledModalFields;
  },
  getIsSelectionDialog(state) {
    return state.isSelectionDialog;
  },
  getLinks(state) {
    return state.links;
  },
  getDisabled(state) {
    return state.isDisabledPicker;
  },
  getSnackbarData(state) {
    return state.snackbarData;
  },
  getDashboardTop(state) {
    return state.dashboardTop;
  },
  getAbortControllers(state) {
    return state.abortControllers;
  },
};
