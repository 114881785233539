















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop() type!: String;
  @Prop({ default: false }) disabled: Boolean | undefined;
  @Prop() method: Function | undefined;
  @Prop() text!: String;
  @Prop() addStyle!: Object;
  @Prop() args: Object | undefined;
}
