import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export function defaultState() {
  return {
    isSelectionDialog: false,
    disabledModalFields: {
      product_tilgung_manual: false,
    },
    abortControllers: null, // Loading reports of different types takes a lot of resources, so when we switch between pages, close previous asynchronous requests and clear the state
    sections: [],
    sumMeta: 0,
    allReportsFestgeld: [],
    allReportsTagesgeld: [],
    allReportsBaugeld: [],
    error: false,
    loading: false,
    loadingPreview: false,
    uploadedReports: {
      tagesgeld: true,
      festgeld: false,
      baugeld: true,
    },
    loadingAllReportsBaugeld: false,
    loadingAllReportsTagesgeld: false,
    loadingAllReportsFestgeld: false,
    updatedReportsTagesgeld: false,
    updatedReportsFestgeld: false,
    updatedReportsBaugeld: false,
    dataTable: {},
    singleReport: {},
    editReport: false,
    newReport: {},
    updateExistingReport: {},
    previewReport: {},
    skeleton: [],
    uploadReport: false,
    result: {
      banken_vermittlerangebote: 0,
      products: [],
    },
    filtersForEditing: {},
    report_type: null,
    is_temporary: true,
    links: {},
    isDisabledPicker: false,
    snackbarData: {
      show: false,
      text: '',
      timeout: 0,
      color: '',
      dark: true,
    },
    dashboardTop: {
      tagesgeldTopZinsen: [],
      festgeldTopZinsen: [],
      festgeldLang: [],
    },
  };
}

const state = defaultState();

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
