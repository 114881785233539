





















import { Component, Vue } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';

@Component({
  directives: {
    ClickOutside,
  },
})
export default class Menu extends Vue {
  open: Boolean = false;

  openMenu() {
    this.open = !this.open;
  }

  hideMenu() {
    this.open = false;
  }
  goWizard() {
    this.$router.push('/');
  }
}
