























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';
import i18n from '@/plugins/i18n';

import Button from '@/components/fmh_components/common/Buttons/Button.vue';
import Menu from '@/components/fmh_components/common/Menu/Menu.vue';
import { BASE_COLOR } from '@/utils/constants';

import { IUser } from '@/interfaces';

@Component({
  components: { Menu, Button },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      logOutSession: 'auth/logOutSession',
    }),
    ...mapMutations({
      clearResult: 'reports/clearResult',
      resetError: 'reports/resetError',
      resetWizardError: 'wizard/resetError',
      clearDatenaboData: 'datenabo/clearDatenaboData',
      resetStateBProfile: 'bankingProfile/resetState',
      resetWettbewerb: 'wettbewerb/resetState',
    }),
  },
})
export default class TopBar extends Vue {
  @Prop() drawer!: Boolean;
  @Prop() mini!: Boolean;
  color = BASE_COLOR;
  title = 'header.bankingPortal';
  user?: IUser;

  clearResult!: MutationMethod;
  clearDatenaboData!: MutationMethod;
  resetStateBProfile!: MutationMethod;
  resetWettbewerb!: MutationMethod;
  logOutSession!: () => Promise<any>;

  @Watch('$route', { immediate: true }) setTitle() {
    const id = this.$router.currentRoute.params.id;
    switch (this.$router.currentRoute.fullPath) {
      case '/':
        return (this.title = 'header.bankingPortal');
      case '/tagesgeld':
        return (this.title = 'Marktbeobachtung - Live-Daten - Tagesgeld');
      case '/festgeld':
        return (this.title = 'Marktbeobachtung - Live-Daten - Festgeld');
      case '/baugeld':
        return (this.title = 'Marktbeobachtung - Live-Daten - Baugeld');
      case '/baugeld/wizard':
        return (this.title = 'Wizard Baugeld');
      case '/festgeld/wizard':
        return (this.title = 'Wizard Festgeld');
      case '/tagesgeld/wizard':
        return (this.title = 'Wizard Tagesgeld');
      case '/wettbewerb-baugeld':
        return (this.title = 'Wettbewerb');
      case '/wettbewerb-festgeld':
        return (this.title = 'Wettbewerb');
      case '/wettbewerb-tagesgeld':
        return (this.title = 'Wettbewerb');
      case '/spezialabonnenten-tagesgeld':
        return (this.title = 'NIBC Tagesgeld');
      case '/spezialabonnenten-festgeld':
        return (this.title = 'NIBC Festgeld');
      case '/kfw-baugeld':
        return (this.title = 'KfW Baugeld');
      case '/banking-profile':
        return (this.title = 'bankingProfile.headline');
      case '/banking-profile/add-new-report':
        return (this.title = 'bankingProfile.headline');
      case `/banking-profile/report/${id}`:
        return (this.title = 'bankingProfile.headline');
      case `/banking-profile/bank-details/${id}`:
        return (this.title = 'bankingProfile.headline');
      default:
        return (this.title = 'header.bankingPortal');
    }
  }

  get userData() {
    return this.user?.fullName || '';
  }
  handleNavigate() {
    if (this.$router.currentRoute.path !== '/') {
      this.$router.push('/');
    }
  }
  logOut() {
    if (process.env.VUE_APP_IS_DEVELOPMENT === 'false') {
      this.clearResult();
      this.clearDatenaboData();
      this.resetStateBProfile();
      this.resetWettbewerb();
      this.logOutSession().then(() => {
        this.$router.push('/login');
      });
      i18n.locale = 'de';
      this.$emit('change-drawer', { drawer: true, mini: false });
    } else {
      return;
    }
  }
}
