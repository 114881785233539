import { instance } from '@/utils';

export default {
  fetchWizardConfigFestgeld({ commit }) {
    return instance
      .get('/api/wizard-festgeld/')
      .then((res) => {
        commit('fetchWizardConfigFestgeldSuccess', res.data.sections);
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  fetchWizardConfigTagesgeld({ commit }) {
    instance
      .get('/api/wizard-tagesgeld/')
      .then((res) => {
        commit('fetchWizardConfigTagesgeldSuccess', res.data.sections);
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  fetchWizardConfigBaugeld() {
    return instance.get('/api/wizard-baugeld/');
  },
};
