import eachOfLimit from 'async/eachOfLimit';

import { instance } from '../../utils/index';
import { modifyAllReports } from '../../utils/helpers';

function reportType(type) {
  switch (type) {
    case 1:
      return 'tagesgeld';
    case 2:
      return 'festgeld';
    case 3:
      return 'baugeld';
    default:
      return 'tagesgeld';
  }
}

export default {
  async fetchTop({ commit }) {
    commit('loading', true);
    try {
      const tagesgeld = await instance.post('/api/graphql', {
        query: `
        {
          tagesgeldTopZinsen {
            data {
               anbieterId
               anbietername
               zinssatz
              }
            }
          }
      `,
      });
      const festgeld = await instance.post('/api/graphql', {
        query: `
        {
          festgeldTopZinsen(laufzeit: [3, 6, 12]) {
           param {
             laufzeit
            }
             data {
                anbieterId
                anbietername
                zinssatz1
                zinssatz2
                zinssatz3
              }
            }
          }
      `,
      });
      const festgeldLang = await instance.post('/api/graphql', {
        query: `
        {
          festgeldTopZinsen(laufzeit: [24, 60, 120]) {
           param {
             laufzeit
            }
             data {
                anbieterId
                anbietername
                zinssatz1
                zinssatz2
                zinssatz3
              }
            }
          }
      `,
      });
      const payload = {
        tagesgeldTopZinsen:
          tagesgeld?.data?.data?.tagesgeldTopZinsen?.data || [],
        festgeldTopZinsen: festgeld?.data?.data?.festgeldTopZinsen?.data || [],
        festgeldLang: festgeldLang?.data?.data?.festgeldTopZinsen?.data || [],
      };
      commit('setDashboardTop', payload);
      commit('loading', false);
    } catch (e) {
      commit('loading', false);
    }
  },
  fetchXLSAlFields({ commit }, payload) {
    return instance.get(
      `api/report/compare/${payload.newId}/${payload.oldId}/?all=true`
    );
  },
  compareReports({ commit }, payload) {
    commit('disabled', true);
    instance
      .get(`api/report/compare/${payload.newId}/${payload.oldId}/`)
      .then((res) => {
        commit(
          'fetchSingleReportSuccess',
          Object.assign(res.data, {
            config: payload.config,
            title: payload.title,
            note: payload.note,
            meta: payload.meta,
            tags: payload.tags,
            slug: payload.slug,
            uid: payload.uid,
          })
        );
      })
      .catch((e) => {
        commit('disabled', false);
        commit('error', true);
        console.log(e.response);
      });
  },
  fetchReportTypes() {
    return instance.get('/api/enums');
  },
  fetchLatestDataOfTheReport({ commit }, payload) {
    return instance.get(`/api/report/${payload.url}`, {
      params: { 'force-reload': true },
    });
  },
  fetchSnapshots({ commit }, payload) {
    commit('disabled', true);
    instance
      .get(`/api${payload.url}`)
      .then((res) => {
        commit(
          'fetchSingleReportSuccess',
          Object.assign(res.data, {
            title: payload.title,
            note: payload.note,
            tags: payload.tags,
            meta: res.data.meta,
            slug: payload.slug,
            uid: payload.uid,
            allBanks: payload.allBanks,
          })
        );
      })
      .catch((e) => {
        commit('disabled', false);
        commit('error', true);
        console.log(e.response);
      });
  },
  fetchCopyReport({ commit }, id) {
    return instance.post(`/api/report-config/${id}/`);
  },
  fetchSingleReport({ commit }, payload) {
    commit('loading', true);
    instance
      .get(`/api/report-config/${payload}/`)
      .then((res) => {
        if (res.data.length) {
          commit('setFetchSingleReport', {
            allBanks:
              res.data[0].config?.allBanks?.filter((bank) =>
                bank.includes('_')
              ) || [],
            report_type: res.data[0].report_type,
            slug: res.data[0].slug,
            title: res.data[0].title,
            uid: res.data[0].uid,
            ...res.data[0],
            config: res.data[0].config,
          });
          instance
            .get(`/api/report/${res.data[0].slug}`)
            .then((res) => {
              commit('loading', false);
              commit('error', false);
              commit('fetchSingleReportSuccess', res.data);
            })
            .catch((e) => {
              commit('loading', false);
              commit('error', true);
              console.log(e.response);
            });
        } else {
          commit('loading', false);
        }
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  async fetchAllReports({ commit }, payload) {
    const controller1 = new AbortController();
    const controller2 = new AbortController();
    const controller3 = new AbortController();
    const controller4 = new AbortController();
    const signal1 = controller1.signal;
    const signal2 = controller2.signal;
    const signal3 = controller3.signal;
    const signal4 = controller4.signal;
    commit('addControllers', [
      controller1,
      controller2,
      controller3,
      controller4,
    ]);

    const report_type = payload?.toString();
    //eslint-disable-next-line
    const key = report_type === '3' ? 'baugeld' : report_type === '2' ? 'festgeld' : 'tagesgeld';
    //eslint-disable-next-line
    const mutateLoading = report_type === '3' ? 'loadingAllReportsBaugeld' : report_type === '2' ? 'loadingAllReportsFestgeld' : 'loadingAllReportsTagesgeld';
    commit(mutateLoading, true);
    try {
      const arr = [];
      const { data } = await instance.get('/api/reports-config/', {
        signal: signal1,
        params: {
          report_type: payload.toString(),
          is_temporary: false,
          ordering: '-timestamp',
        },
      });
      if (data?.length) {
        const payload = data.length > 3 ? data.slice(0, 3) : data;
        const modified = modifyAllReports(payload, report_type, 'latest');
        modified.forEach((item) => {
          arr.push(item);
        });
      }
      const reportsAutoUpdate = await instance.get('/api/reports-config/', {
        signal: signal2,
        params: {
          selection_type: 'selection_autoupdate',
          is_temporary: false,
          report_type: payload.toString(),
        },
      });
      if (reportsAutoUpdate?.data?.length) {
        const modified = modifyAllReports(
          reportsAutoUpdate.data,
          report_type,
          'autoUpdate'
        );
        modified.forEach((item) => {
          arr.push(item);
        });
      }
      const otherReports = await instance.get('/api/reports-config/', {
        signal: signal3,
        params: {
          selection_type: 'selection_longterm,selection_individualupdate',
          is_temporary: false,
          report_type: payload.toString(),
        },
      });
      if (otherReports?.data?.length) {
        const modified = modifyAllReports(
          otherReports.data,
          report_type,
          'other'
        );
        modified.forEach((item) => {
          arr.push(item);
        });
      }
      const result = arr.map((item, key) => {
        return Object.assign(item, { key: key });
      });
      commit('mapSkeleton', { result, report_type });
      commit('setUploadedReports', { [key]: true });
      await eachOfLimit(result, 3, async (item, key, callback) => {
        try {
          const fetchReportData = await instance
            .get(`/api/report/${item.slug}`, { signal: signal4 })
            .then((res) => res.data)
            .catch((e) => {
              if (e) {
                const payload = {
                  ...result[key],
                  error: true,
                };
                commit('fetchAllReportsSuccess', {
                  payload,
                  report_type,
                });
              }
            });
          const payload = {
            ...fetchReportData,
            ...result[key],
          };
          callback(
            null,
            commit('fetchAllReportsSuccess', {
              payload,
              report_type,
            })
          );
        } catch (e) {
          if (e) {
            callback(e, commit(mutateLoading, false), commit('error', true));
          }
        }
      });
      commit(mutateLoading, false);
    } catch (e) {
      if (e && e.message !== 'canceled') {
        commit('setUploadedReports', { [key]: false });
        commit(mutateLoading, false);
        commit('error', true);
        console.log(e.response);
      }
    }
  },
  deleteReport({ commit }, payload) {
    return instance.delete(`/api/report-config/${payload.slug}/`);
  },
  async fetchPreviewReport({ commit }, payload) {
    commit('loadingPreview', true);
    await instance
      .post('/api/report-config/', payload, { params: { preview: true } })
      .then((res) => {
        if (res.data) {
          commit('fetchPreviewReportSuccess', res.data);
          instance
            .get(`/api/report/${res.data.slug}`)
            .then((res) => {
              if (res.data) {
                commit('setPreviewReport', res.data);
                commit('loadingPreview', false);
                commit('error', false);
              }
            })
            .catch((e) => {
              commit('loadingPreview', false);
              commit('error', true);
              console.log(e.response);
            });
        }
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loadingPreview', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  fetchUpdatedReport({ commit }, payload) {
    commit('loadingPreview', true);
    instance
      .put(`/api/report-config/${payload.slug}/`, payload)
      .then((res) => {
        if (res) {
          commit('setUpdateReport', res.data);
          instance
            .get(`/api/report/${res.data.slug}`)
            .then((res) => {
              if (res.data) {
                commit('setPreviewReport', res.data);
                commit('updateReportSuccess', {
                  payload: res.data,
                  editReport: true,
                });
                commit('loadingPreview', false);
                commit('error', false);
              }
            })
            .catch((e) => {
              commit('loadingPreview', false);
              commit('error', true);
              console.log(e.response);
            });
        }
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loadingPreview', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  sendUpdatedExistingReport({ commit }, data) {
    commit('loadingPreview', true);
    const { config, ...rest } = data;
    const { tags, note, ...res } = config;
    const n = note ? note : null;
    const t = tags ? tags : [];
    const payload = Object.assign(
      rest,
      { config: res },
      { note: n, tags: t },
      {}
    );
    instance
      .put(`/api/report-config/${rest.slug}/`, payload)
      .then((res) => {
        if (res.data) {
          commit('setUpdateExistingReport', res.data);
          instance
            .get(`/api/report/${res.data.slug}`)
            .then((res) => {
              if (res.data) {
                commit('setPreviewReport', res.data);
                commit('updateReportSuccess', {
                  editReport: true,
                });
                commit('loadingPreview', false);
                commit('error', false);
              }
            })
            .catch((e) => {
              commit('loadingPreview', false);
              commit('error', true);
              console.log(e.response);
            });
        }
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  updateReport({ commit }, data) {
    commit('loading', true);
    const { config, ...rest } = data;
    const { tags, note, ...res } = config;
    const n = note ? note : null;
    const t = tags ? tags : [];

    const payload = Object.assign(
      rest,
      { config: res },
      { note: n, tags: t },
      {}
    );
    instance
      .put(`/api/report-config/${rest.slug}/`, payload)
      .then((res) => {
        if (res.data) {
          const slug = res.data.slug;
          const productType = reportType(res.data.report_type);
          commit('setUpdateReport', res.data);
          instance
            .get(`/api/report/${slug}`)
            .then((res) => {
              if (res.data) {
                commit('updateReportSuccess', {
                  payload: res.data,
                  editReport: false,
                  productType,
                  slug,
                });
                commit('loading', false);
                commit('error', false);
                commit('uploadReportSuccess', true);
              }
            })
            .catch((e) => {
              commit('loading', false);
              commit('error', true);
              console.log(e.response);
            });
        }
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  async updateExistingReport({ commit }, data) {
    const { config, field, ...rest } = data;
    const payload = {};

    if (field === 'note') {
      Object.assign(payload, { note: rest.note, config });
    }
    if (field === 'tags') {
      Object.assign(payload, { tags: rest.tags, config });
    }
    if (field === 'title') {
      Object.assign(payload, { title: rest.title, config });
    }

    try {
      await instance.patch(`/api/report-config/${rest.slug}/`, payload);
    } catch (e) {
      commit('error', true);
    }
  },
  sendAndUpdateExistingReport({ commit }, data) {
    commit('loading', true);
    const { config, ...rest } = data;
    const { tags, note, ...res } = config;
    const n = note ? note : null;
    const t = tags ? tags : [];

    const payload = Object.assign(
      rest,
      { config: res },
      { note: n, tags: t },
      {}
    );
    instance
      .put(`/api/report-config/${payload.slug}/`, payload)
      .then((res) => {
        if (res.data) {
          const slug = res.data.slug;
          const productType = reportType(res.data.report_type);
          instance
            .get(`/api/report/${slug}`)
            .then((res) => {
              if (res.data) {
                commit('updateExistingReportSuccess', {
                  payload: res.data,
                  editReport: false,
                  productType,
                  slug,
                });
                commit('uploadReportSuccess', true);
              }
            })
            .catch((e) => {
              commit('loading', false);
              commit('error', true);
              console.log(e.response);
            });
        }
      })
      .catch((e) => {
        if (e.response.status !== 401) {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  editReport({ commit }, payload) {
    return instance.get(`/api/report-config/${payload}/`);
  },
};
