import getEnv from '@/utils/env';
import { SETTINGS } from '../../wettbewerbsvergleich/src/utils/constants';

export const INTEREST_CREDIT_TAGESGELD = {
  0: 'Monatsende',
  1: 'Quartalsende',
  2: 'Jahresende',
  3: 'Täglich',
  4: 'Halbjahresende',
  5: 'Monatsanfang',
};

export const INTEREST_CREDIT_FESTGELD = {
  0: 'Monatlich',
  1: 'Vierteljährlich',
  2: 'Halbjährlich',
  3: 'Jährlich',
  4: 'Laufzeitende',
};

export const INTEREST_CALCULATION = {
  0: 'Deutsche Methode',
  1: 'Eurozins Methode',
  2: 'Englische Methode',
  3: 'Effektivzinsmethode',
};

export const COLOR = '#1194db';
export const BASE_COLOR = '#83929b';
export const BASE_BLACK_COLOR = '#181818';
export const SELECTED_COLOR = '#00ab97';

export const TAG_COLOR = {
  'erweiterte Einlagensicherung': 'blue',
  'deutsche Anbieter': 'green',
  Vermittler: 'blue',
  'flexible Angebote': 'red',
};
export const PUBLIC_PAGES = [
  '/rate-chart',
  '/login',
  '/forgot-password',
  '/reset-password',
];
export const NAMES_PUBLIC_PAGES = [
  'rate-chart',
  'login',
  'forgot-password',
  'reset-password',
];
export const NAVIGATION_CONFIG = [
  {
    isPanel: false,
    permission: 'rest',
    to: '/',
    icon: 'mdi-view-dashboard',
    title: 'Dashboard',
    items: [],
  },
  {
    isPanel: true,
    className: 'parent-header',
    icon: 'mdi-clock-time-three-outline',
    title: 'Live-Daten',
    permission: 'rest',
    tooltip: true,
    nudge: {
      true: -90,
      false: 40,
    },
    tooltipText:
      'Live-Daten: Ihre Marktbeobachtung. Zinsen und Konditionen\ndiverser Anbieter abrufen, tracken und vergleichen.',
    items: [
      { to: '/baugeld', title: 'Baugeld', permission: 'mortgage.liveData' },
      {
        to: '/tagesgeld',
        title: 'Tagesgeld',
        permission: 'callMoney.liveData',
      },
      {
        to: '/festgeld',
        title: 'Festgeld',
        permission: 'timeDeposits.liveData',
      },
    ],
  },
  {
    isPanel: true,
    className: 'parent-header',
    icon: 'mdi-chart-bar',
    title: 'Historische Daten',
    permission: 'rest',
    tooltip: true,
    nudge: {
      true: -100,
      false: 40,
    },
    tooltipText:
      'Wettbewerb: Ihre Marktübersicht. Visualisierte Markt- und Produktentwicklung\nfür Kredite und Anlageprodukte.',
    items: [
      {
        to: '/wettbewerb-baugeld',
        title: 'Baugeld',
        permission: 'mortgage.competition',
      },
      {
        to: '/wettbewerb-tagesgeld',
        title: 'Tagesgeld',
        permission: 'callMoney.competition',
      },
      {
        to: '/wettbewerb-festgeld',
        title: 'Festgeld',
        permission: 'timeDeposits.competition',
      },
    ],
  },
  {
    isPanel: false,
    permission: [
      'callMoney.interestCharts',
      'mortgage.interestCharts',
      'timeDeposits.interestCharts',
    ],
    to: '/chart-generator',
    icon: 'mdi-chart-line-variant',
    title: 'Zinscharts',
    nudge: {
      true: 10,
      false: 15,
    },
    tooltip: true,
    tooltipText:
      'Zinscharts: Visualisierungen von Zins- und Marktentwicklung\nselbst erstellen und exportieren.',
    items: [],
  },
  {
    isPanel: false,
    permission: [
      'callMoney.profile',
      'mortgage.profile',
      'timeDeposits.profile',
    ],
    to: '/banking-profile',
    icon: 'mdi-account-outline',
    title: 'Anbieterprofile',
    tooltip: true,
    nudge: {
      true: 10,
      false: 15,
    },
    tooltipText:
      'Anbieterprofile: Stammdaten und Bilanzkennzahlen der Player\nauf dem Finanzmarkt abrufen und ' +
      'vergleichen - europaweit.',
    items: [],
  },

  {
    isPanel: true,
    className: 'parent-header',
    icon: 'mdi-format-list-bulleted',
    title: 'NIBC',
    permission: 'product.nibc',
    tooltip: false,
    items: [
      {
        to: '/spezialabonnenten-tagesgeld',
        title: 'Tagesgeld',
        permission: 'product.nibc',
      },
      {
        to: '/spezialabonnenten-festgeld',
        title: 'Festgeld',
        permission: 'product.nibc',
      },
    ],
  },
  {
    isPanel: true,
    className: 'parent-header',
    icon: 'mdi-format-list-bulleted',
    title: 'KfW',
    permission: 'product.kfw',
    tooltip: false,
    items: [
      { to: '/kfw-baugeld', title: 'Baugeld', permission: 'product.kfw' },
    ],
  },
];

export const HEADLINES = {
  latest: 'common.dataIterator.groupHeadline.latest',
  autoUpdate: 'common.dataIterator.groupHeadline.autoUpdate',
  other: 'common.dataIterator.groupHeadline.other',
};

export const FIELDS_TRANSLATE = {
  leverageRatio: 'leverageRatio',
  eigenkapital: 'equity',
  bilanzsumme: 'totalAssets',
  marktkapitalisierung: 'marketCapitalization',
  gesamtkapitalquote: 'totalCapitalRatio',
  kernkapitalquote: 'coreCapitalRatio',
  eigenkapitalrendite: 'returnOnEquity',
  mitarbeiter: 'employees',
  filialen: 'branchOffices',
  standortlaender: 'locations',
  schwerpunkte: 'keyAreas',
  bankratings: 'bankRatings',
  landkuerzel: 'headOffice',
  gewinn: 'profit',
};
export const SERIES_BACKGROUND_COLOR = {
  0: '#ff5a5f',
  1: '#7b0051',
  2: '#007A87',
  3: '#00d1c1',
  4: '#8ce071',
  5: '#ffb400',
  6: '#b4a76c',
  7: '#ff8083',
  8: '#cc0086',
  9: '#00a1b3',
  10: '#00ffeb',
  11: '#bbedab',
  12: '#ffd266',
  13: '#cbc29a',
  14: '#ff3339',
  15: '#ff1ab1',
  16: '#005c66',
  17: '#00b3a5',
  18: '#55d12e',
  19: '#b37e00',
  20: '#988b4e',
};
export const DEFAULT_SELECTED_HEADERS_BANKING_PROFILE = [
  {
    value: 'gesamtkapitalquote',
    text: 'totalCapitalRatio',
    sortable: true,
  },
  { value: 'eigenkapitalrendite', text: 'returnOnEquity', sortable: true },
  { value: 'schwerpunkte', text: 'keyAreas', sortable: false },
  { value: 'bankratings', text: 'bankRatings', sortable: false },
];
export const DEFAULT_HEADERS_BANKING_PROFILE = [
  { value: 'ordinalNumber', text: '', sortable: false },
  { value: 'anbietername', text: 'provider', sortable: true },
  { value: 'standortlaender', text: 'locations', sortable: false },
  { value: 'landkuerzel', text: 'headOffice', sortable: true },
];

export const ADDITIONAL_HEADERS_BANKING_PROFILE = [
  { value: 'eigenkapital', text: 'equity', sortable: true },
  { value: 'bilanzsumme', text: 'totalAssets', sortable: true },
  { value: 'gewinn', text: 'profit', sortable: true },
  {
    value: 'provisionseinnahmen',
    text: 'commissionIncome',
    sortable: false,
    children: [
      {
        value: 'provisionsaufwendungen',
        text: 'commissionExpenses',
        sortable: true,
      },
      {
        value: 'provisionsertrag',
        text: 'netCommissionIncome',
        sortable: true,
      },
      {
        value: 'provisionsueberschuss',
        text: 'commissionSurplus',
        sortable: true,
      },
    ],
  },
  {
    value: 'kreditvolumen',
    text: 'creditVolume',
    sortable: false,
    children: [
      { value: 'grundpfandrecht', text: 'lienOnRealProperty', sortable: true },
      {
        value: 'forderungenKreditinstitute',
        text: 'receivablesFromBanks',
        sortable: true,
      },
      {
        value: 'forderungenKunden',
        text: 'receivablesCustomers',
        sortable: true,
      },
    ],
  },
  {
    value: 'anlagevolumen',
    text: 'investmentVolume',
    sortable: false,
    children: [
      { value: 'spareinlagen', text: 'savingsDeposits', sortable: true },
      {
        value: 'andereVerbindlichkeiten',
        text: 'otherliabilities',
        sortable: true,
      },
      {
        value: 'summeVerbindlichkeiten',
        text: 'totalPayables',
        sortable: true,
      },
    ],
  },
  { value: 'einlagensicherung', text: 'depositInsurance', sortable: true },
  {
    value: 'marktkapitalisierung',
    text: 'marketCapitalization',
    sortable: true,
  },
  { value: 'eigenkapitalrendite', text: 'returnOnEquity', sortable: true },
  { value: 'kernkapitalquote', text: 'coreCapitalRatio', sortable: true },
  { value: 'gesamtkapitalquote', text: 'totalCapitalRatio', sortable: true },
  { value: 'leverageRatio', text: 'leverageRatio', sortable: true },
  { value: 'mitarbeiter', text: 'employees', sortable: true },
  { value: 'filialen', text: 'branchOffices', sortable: true },
  { value: 'schwerpunkte', text: 'keyAreas', sortable: false },
  { value: 'gesellschafter', text: 'shareholder', sortable: true },
  { value: 'tochtergesellschaften', text: 'subsidiaries', sortable: true },
  { value: 'bankratings', text: 'bankRatings', sortable: false },
  { value: 'gruendungsort', text: 'foundationLocation', sortable: true },
  { value: 'gruendungsjahr', text: 'foundationYear', sortable: true },
];

export const SUFFIXES = {
  provisionsueberschuss: 'Mio €',
  gewinn: 'Mio €',
  provisionsertrag: 'Mio €',
  provisionsaufwendungen: 'Mio €',
  summeVerbindlichkeiten: 'Mio €',
  andereVerbindlichkeiten: 'Mio €',
  spareinlagen: 'Mio €',
  forderungenKunden: 'Mio €',
  forderungenKreditinstitute: 'Mio €',
  grundpfandrecht: 'Mio €',
  bilanzsumme: 'Mio €',
  eigenkapital: 'Mio €',
  marktkapitalisierung: 'Mio €',
  leverageRatio: '%',
  gesamtkapitalquote: '%',
  kernkapitalquote: '%',
  gesellschafter: '%',
  gesamtkapitalquoteLand: '%',
  gesamtkapitalquoteDurchschnitt: '%',
  eigenkapitalrenditeDurchschnitt: '%',
  eigenkapitalrendite: '%',
};
export const SUFFIXES_DEPOSIT = {
  0: '',
  1: 'Tsd',
  2: 'Mio',
  3: 'Mrd',
  4: 'unbegrenzt',
};
export const LIVE_DATA_CONFIG = [
  {
    text: 'Baugeld',
    value: { link: '/baugeld', permission: 'mortgage.liveData' },
  },
  {
    text: 'Festgeld',
    value: { link: '/festgeld', permission: 'callMoney.liveData' },
  },
  {
    text: 'Tagesgeld',
    value: { link: '/tagesgeld', permission: 'timeDeposits.liveData' },
  },
];
export const WETTBEWERB_CONFIG = [
  {
    text: 'Baugeld',
    value: {
      link: '/wettbewerb-baugeld',
      permission: 'mortgage.competition',
    },
  },
  {
    text: 'Festgeld',
    value: {
      link: '/wettbewerb-festgeld',
      permission: 'timeDeposits.competition',
    },
  },
  {
    text: 'Tagesgeld',
    value: {
      link: '/wettbewerb-tagesgeld',
      permission: 'callMoney.competition',
    },
  },
];

export const TEXT_ACTION_BUTTONS = {
  confirm: 'Ja',
  reject: 'Nein',
};

export const SNACK_BAR_DATA = {
  text: 'Bei der API Anfrage trat ein Fehler auf.',
  color: '',
  dark: true,
  show: true,
  timeout: 3000,
};
export const LANGUAGES = [
  { flag: 'eu', value: 'en', title: 'English' },
  { flag: 'de', value: 'de', title: 'German' },
];

export const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 5,
      right: 40,
      top: 20,
      bottom: 20,
    },
  },
  scales: {
    x: {
      id: 'x-axis-0',
      grid: {
        drawTicks: false,
        borderDash: [2, 4],
        color: SETTINGS.gridLinesColor,
      },
      ticks: {
        autoSkip: true,
        beginAtZero: true,
        maxTicksLimit: window.innerWidth / 100,
        padding: 10,
        maxRotation: 0,
        minRotation: 0,
      },
    },
    y: {
      id: 'y-axis-0',
      grid: {
        tickMarkLength: 10,
        borderDash: [3, 3],
        color: SETTINGS.gridLinesColor,
      },
      scaleLabel: {
        display: true,
      },
      ticks: {
        padding: 10,
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      onClick: function () {
        return false;
      },
      maxWidth: 10,
      fullSize: true,
      labels: {
        font: {
          weight: 'bold',
          size: 14,
          fontColor: '#181818',
        },
      },
    },
  },
};
export const OFFERS = {
  0: 'Alle Angebote',
  1: 'Nur Neukunden Angebote',
  2: 'Bestandkunden Angebote',
};
export const PROFILE_DATA_FIELDS = {
  kreditvolumen:
    'kreditvolumen { \n grundpfandrecht \n forderungenKreditinstitute \n forderungenKunden \n }',
  anlagevolumen:
    'anlagevolumen { \n spareinlagen \n andereVerbindlichkeiten \n summeVerbindlichkeiten \n }',
  provisionseinnahmen:
    'provisionseinnahmen { \n provisionsaufwendungen \n provisionsertrag \n provisionsueberschuss \n }',
  gewinn: 'gewinn { \n jahr \n gewinn \n }',
  anbietername: 'anbietername',
  marke: 'marke',
  landname: 'landname',
  landkuerzel: 'landkuerzel',
  gruendungsort: 'gruendungsort',
  gruendungsjahr: 'gruendungsjahr',
  datumLetzteAktualisierung: 'datumLetzteAktualisierung',
  standorte: 'standorte',
  standortlaender: 'standortlaender',
  boersennotiert: 'boersennotiert',
  gesellschafter: 'gesellschafter { \n gesellschaft \n anteil \n }',
  tochtergesellschaften: 'tochtergesellschaften',
  mitarbeiter: 'mitarbeiter { \n jahr \n anzahl \n }',
  filialen: 'filialen { \n jahr \n anzahl \n }',
  kerngeschaeft: 'kerngeschaeft { \n produktname \n produktart \n }',
  schwerpunkte: 'schwerpunkte',
  hashtags: 'hashtags',
  hypothekenvolumen:
    'hypothekenvolumen { \n bezeichnung \n hypothekenvolumen \n }',
  kernkapitalquote: 'kernkapitalquote { \n jahr \n kernkapitalquote \n }',
  marktkapitalisierung:
    'marktkapitalisierung { \n jahr \n marktkapitalisierung \n }',
  eigenkapital: 'eigenkapital { \n jahr \n eigenkapital \n }',
  eigenkapitalrendite:
    'eigenkapitalrendite { \n jahr \n eigenkapitalrendite \n }',
  eigenkapitalrenditeDurchschnitt: 'eigenkapitalrenditeDurchschnitt',
  gesamtkapitalquote: 'gesamtkapitalquote { \n jahr \n gesamtkapitalquote \n }',
  gesamtkapitalquoteDurchschnitt: 'gesamtkapitalquoteDurchschnitt',
  gesamtkapitalquoteLand: 'gesamtkapitalquoteLand',
  bilanzsumme: 'bilanzsumme { \n jahr \n bilanzsumme \n }',
  leverageRatio: 'leverageRatio { \n jahr \n leverageRatio \n }',
  ratingMuttergesellschaft: 'ratingMuttergesellschaft',
  bankratings: 'bankratings { \n ratingagentur \n rating \n }',
  landratings: 'landratings { \n rating \n ratingagentur \n }',
  einlagensicherung:
    'einlagensicherung { \n bezeichnung \n infotext \n landname \n betrag \n betragZahlenname \n waehrung \n}',
};

export const PROFILE_TABLE_DATA_DEMO = [
  {
    id: 520,
    anbieterLogo: '',
    landname: 'Slowakei',
    finanzdaten: [
      {
        jahr: 2022,
        waehrung: 'EUR',
        gewinn: 14,
        eigenkapital: 750,
        marktkapitalisierung: null,
        bilanzsumme: 4832,
      },
      {
        jahr: 2021,
        waehrung: 'EUR',
        gewinn: 58,
        eigenkapital: 749,
        marktkapitalisierung: null,
        bilanzsumme: 4905,
      },
      {
        jahr: 2020,
        waehrung: 'EUR',
        gewinn: 44,
        eigenkapital: 687,
        marktkapitalisierung: null,
        bilanzsumme: 4438,
      },
      {
        jahr: 2019,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2018,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2017,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2016,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
    ],
    anbietername: '365.bank',
    landkuerzel: 'SK',
    standortlaender: ['Slowakei'],
    schwerpunkte: ['Hypotheken', 'Kredite', 'Onlinebanking'],
    bankratings: [
      {
        ratingagentur: 'S&P',
        rating: '',
      },
      {
        ratingagentur: 'Fitch',
        rating: 'BB-',
      },
    ],
    gesellschafter: [],
    tochtergesellschaften: [],
    filialen: [
      {
        jahr: 2022,
        anzahl: '365',
      },
    ],
    gruendungsjahr: '1992',
    mitarbeiter: [
      {
        jahr: 2022,
        anzahl: '1234',
      },
      {
        jahr: 2021,
        anzahl: '1211',
      },
      {
        jahr: 2020,
        anzahl: '1178',
      },
    ],
    gruendungsort: 'Bratislava',
    kernkapitalquote: [
      {
        jahr: 2022,
        kernkapitalquote: 18.86,
      },
      {
        jahr: 2021,
        kernkapitalquote: 18.92,
      },
      {
        jahr: 2020,
        kernkapitalquote: 17.63,
      },
    ],
    eigenkapitalrendite: null,
    gesamtkapitalquote: [
      {
        jahr: 2022,
        gesamtkapitalquote: 19.09,
      },
      {
        jahr: 2021,
        gesamtkapitalquote: 19.14,
      },
      {
        jahr: 2020,
        gesamtkapitalquote: 17.85,
      },
    ],
    leverageRatio: null,
    einlagensicherung: [
      {
        bezeichnung: 'Gesetzliche Einlagensicherung',
        infotext:
          'Gesetzliche Einlagensicherung bis 100.000 Euro durch die slowakische Einlagensicherung (www.fovsr.sk).',
        landname: 'Slowakei',
        betrag: 100000,
        betragZahlenname: 0,
        waehrung: 'EUR',
      },
    ],
    eigenkapital: [
      {
        jahr: 2022,
        eigenkapital: 750,
      },
      {
        jahr: 2021,
        eigenkapital: 749,
      },
      {
        jahr: 2020,
        eigenkapital: 687,
      },
    ],
    bilanzsumme: [
      {
        jahr: 2022,
        bilanzsumme: 4832,
      },
      {
        jahr: 2021,
        bilanzsumme: 4905,
      },
      {
        jahr: 2020,
        bilanzsumme: 4438,
      },
    ],
    boersennotiert: false,
    marktkapitalisierung: null,
    kreditvolumen: [
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 0,
        forderungenKunden: 3216,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 0,
        forderungenKunden: 3140,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 0,
        forderungenKunden: 2767,
      },
    ],
    anlagevolumen: [
      {
        spareinlagen: 3937,
        andereVerbindlichkeiten: 11,
        summeVerbindlichkeiten: 4082,
      },
      {
        spareinlagen: 4006,
        andereVerbindlichkeiten: 14,
        summeVerbindlichkeiten: 4156,
      },
      {
        spareinlagen: 3671,
        andereVerbindlichkeiten: 12,
        summeVerbindlichkeiten: 3751,
      },
    ],
    provisionseinnahmen: [
      {
        provisionsaufwendungen: 6,
        provisionsertrag: 15,
        provisionsueberschuss: 9,
      },
      {
        provisionsaufwendungen: 27,
        provisionsertrag: 63,
        provisionsueberschuss: 36,
      },
      {
        provisionsaufwendungen: 26,
        provisionsertrag: 62,
        provisionsueberschuss: 35,
      },
    ],
    gewinn: [
      {
        jahr: 2022,
        gewinn: 14,
      },
      {
        jahr: 2021,
        gewinn: 58,
      },
      {
        jahr: 2020,
        gewinn: 44,
      },
    ],
  },
  {
    id: 242,
    anbieterLogo:
      'https://www.fmh.de/resources/assets/1185/wide/c5c1290338d1c26c8722469cc35e340c68037275-abcbank-120x40.jpg',
    landname: 'Deutschland',
    finanzdaten: [
      {
        jahr: 2022,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2021,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2020,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 220,
        marktkapitalisierung: null,
        bilanzsumme: 2812,
      },
      {
        jahr: 2019,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 217,
        marktkapitalisierung: null,
        bilanzsumme: 2645,
      },
      {
        jahr: 2018,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2017,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2016,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
    ],
    anbietername: 'abcbank',
    landkuerzel: 'DE',
    standortlaender: ['Deutschland'],
    schwerpunkte: [
      'Ankauf von Leasing-, Mietkauf- und Factoringforderungen der abcfinance GmbH',
    ],
    bankratings: [
      {
        ratingagentur: 'S&P',
        rating: '',
      },
      {
        ratingagentur: 'Fitch',
        rating: '',
      },
    ],
    gesellschafter: [],
    tochtergesellschaften: null,
    filialen: null,
    gruendungsjahr: '-',
    mitarbeiter: [
      {
        jahr: 2019,
        anzahl: '128',
      },
    ],
    gruendungsort: 'Köln',
    kernkapitalquote: [
      {
        jahr: 2020,
        kernkapitalquote: 14.56,
      },
      {
        jahr: 2019,
        kernkapitalquote: 14.64,
      },
    ],
    eigenkapitalrendite: null,
    gesamtkapitalquote: [
      {
        jahr: 2020,
        gesamtkapitalquote: 14.56,
      },
      {
        jahr: 2019,
        gesamtkapitalquote: 14.64,
      },
    ],
    leverageRatio: null,
    einlagensicherung: [
      {
        bezeichnung: 'Erweiterte Einlagensicherung',
        infotext:
          'Zusätzlich besteht eine erweiterte Absicherung in Millionenhöhe durch den Einlagensicherungsfonds des Bundesverbands deutscher Banken – BdB (www.bdb.de).',
        landname: 'Deutschland',
        betrag: 32.955,
        betragZahlenname: 2,
        waehrung: 'EUR',
      },
    ],
    eigenkapital: [
      {
        jahr: 2020,
        eigenkapital: 220,
      },
      {
        jahr: 2019,
        eigenkapital: 217,
      },
    ],
    bilanzsumme: [
      {
        jahr: 2020,
        bilanzsumme: 2812,
      },
      {
        jahr: 2019,
        bilanzsumme: 2645,
      },
    ],
    boersennotiert: null,
    marktkapitalisierung: null,
    kreditvolumen: null,
    anlagevolumen: null,
    provisionseinnahmen: null,
    gewinn: null,
  },
  {
    id: 289,
    anbieterLogo:
      'https://www.fmh.de/resources/assets/1186/wide/8c64254ec4509f40ea76791646211b5d382ac9cb-ABK AllgBeamten Bank 600x200.jpg',
    landname: 'Deutschland',
    finanzdaten: [
      {
        jahr: 2022,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2021,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2020,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2019,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2018,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2017,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2016,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
    ],
    anbietername: 'ABK Allgemeine Beamten Bank',
    landkuerzel: 'DE',
    standortlaender: ['Deutschland'],
    schwerpunkte: ['Kredit- und Einlagengeschäft'],
    bankratings: [
      {
        ratingagentur: 'S&P',
        rating: '',
      },
      {
        ratingagentur: 'Fitch',
        rating: '',
      },
    ],
    gesellschafter: [],
    tochtergesellschaften: null,
    filialen: [
      {
        jahr: 2022,
        anzahl: '2',
      },
    ],
    gruendungsjahr: '2007',
    mitarbeiter: [
      {
        jahr: 2020,
        anzahl: '80',
      },
      {
        jahr: 2019,
        anzahl: '80',
      },
    ],
    gruendungsort: 'Berlin',
    kernkapitalquote: [
      {
        jahr: 2020,
        kernkapitalquote: 14.82,
      },
      {
        jahr: 2019,
        kernkapitalquote: 15.43,
      },
    ],
    eigenkapitalrendite: null,
    gesamtkapitalquote: [
      {
        jahr: 2020,
        gesamtkapitalquote: 15.58,
      },
      {
        jahr: 2019,
        gesamtkapitalquote: 16.2,
      },
    ],
    leverageRatio: null,
    einlagensicherung: [
      {
        bezeichnung: 'Erweiterte Einlagensicherung',
        infotext:
          'Zusätzlich besteht eine erweiterte Absicherung in Millionenhöhe durch den Einlagensicherungsfonds des Bundesverbands deutscher Banken – BdB (www.bdb.de).',
        landname: 'Deutschland',
        betrag: 8.373,
        betragZahlenname: 2,
        waehrung: 'EUR',
      },
    ],
    eigenkapital: null,
    bilanzsumme: null,
    boersennotiert: false,
    marktkapitalisierung: null,
    kreditvolumen: null,
    anlagevolumen: null,
    provisionseinnahmen: null,
    gewinn: null,
  },
  {
    id: 279,
    anbieterLogo:
      'https://www.fmh.de/resources/assets/1189/wide/37aea6c855e23ef527211f6d4d479a8697508995-Addiko-Bank.jpg',
    landname: 'Österreich',
    finanzdaten: [
      {
        jahr: 2022,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: 205.7,
        bilanzsumme: null,
      },
      {
        jahr: 2021,
        waehrung: 'EUR',
        gewinn: 236.3,
        eigenkapital: 805,
        marktkapitalisierung: null,
        bilanzsumme: 5842,
      },
      {
        jahr: 2020,
        waehrung: 'EUR',
        gewinn: 234.5,
        eigenkapital: 852,
        marktkapitalisierung: null,
        bilanzsumme: 5915,
      },
      {
        jahr: 2019,
        waehrung: 'EUR',
        gewinn: 250.2,
        eigenkapital: 861,
        marktkapitalisierung: null,
        bilanzsumme: 6084,
      },
      {
        jahr: 2018,
        waehrung: 'EUR',
        gewinn: 235.5,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2017,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2016,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
    ],
    anbietername: 'Addiko Bank',
    landkuerzel: 'AT',
    standortlaender: [
      'Österreich',
      'Kroatien',
      'Slowenien',
      'Bosnien & Herzegowina',
      'Serbien',
      'Montenegro',
    ],
    schwerpunkte: [
      'Betriebsmittelkredite (KMU)',
      'Direktkredite für Endverbraucher',
      'SME-Kreditgeschäft',
    ],
    bankratings: [
      {
        ratingagentur: 'S&P',
        rating: '',
      },
      {
        ratingagentur: 'Fitch',
        rating: '',
      },
    ],
    gesellschafter: null,
    tochtergesellschaften: [],
    filialen: [
      {
        jahr: 2022,
        anzahl: '160',
      },
      {
        jahr: 2021,
        anzahl: '155',
      },
      {
        jahr: 2020,
        anzahl: '168',
      },
      {
        jahr: 2019,
        anzahl: '179',
      },
    ],
    gruendungsjahr: '2010',
    mitarbeiter: [
      {
        jahr: 2020,
        anzahl: '2675',
      },
      {
        jahr: 2019,
        anzahl: '2739',
      },
    ],
    gruendungsort: 'Wien',
    kernkapitalquote: [
      {
        jahr: 2021,
        kernkapitalquote: 10.1,
      },
      {
        jahr: 2020,
        kernkapitalquote: 8.2,
      },
      {
        jahr: 2019,
        kernkapitalquote: 14.1,
      },
    ],
    eigenkapitalrendite: [
      {
        jahr: 2021,
        eigenkapitalrendite: 14.1,
      },
      {
        jahr: 2020,
        eigenkapitalrendite: 14.1,
      },
      {
        jahr: 2019,
        eigenkapitalrendite: 14.1,
      },
    ],
    gesamtkapitalquote: [
      {
        jahr: 2021,
        gesamtkapitalquote: 22.2,
      },
      {
        jahr: 2020,
        gesamtkapitalquote: 20.3,
      },
      {
        jahr: 2019,
        gesamtkapitalquote: 17.7,
      },
    ],
    leverageRatio: [
      {
        jahr: 2021,
        leverageRatio: 5.9,
      },
      {
        jahr: 2020,
        leverageRatio: 5.9,
      },
    ],
    einlagensicherung: [
      {
        bezeichnung: 'Gesetzliche Einlagensicherung',
        infotext:
          'Gesetzliche Einlagensicherung bis 100.000 Euro durch die österreichische Einlagensicherung (www.einlagensicherung.at).',
        landname: 'Österreich',
        betrag: 100000,
        betragZahlenname: 0,
        waehrung: 'EUR',
      },
    ],
    eigenkapital: [
      {
        jahr: 2021,
        eigenkapital: 805,
      },
      {
        jahr: 2020,
        eigenkapital: 852,
      },
      {
        jahr: 2019,
        eigenkapital: 861,
      },
    ],
    bilanzsumme: [
      {
        jahr: 2021,
        bilanzsumme: 5842,
      },
      {
        jahr: 2020,
        bilanzsumme: 5915,
      },
      {
        jahr: 2019,
        bilanzsumme: 6084,
      },
    ],
    boersennotiert: true,
    marktkapitalisierung: [
      {
        jahr: 2022,
        marktkapitalisierung: 205.7,
      },
    ],
    kreditvolumen: [
      {
        grundpfandrecht: 3279,
        forderungenKreditinstitute: 0,
        forderungenKunden: 0,
      },
      {
        grundpfandrecht: 3585,
        forderungenKreditinstitute: 0,
        forderungenKunden: 0,
      },
      {
        grundpfandrecht: 3872,
        forderungenKreditinstitute: 0,
        forderungenKunden: 0,
      },
      {
        grundpfandrecht: 3787,
        forderungenKreditinstitute: 0,
        forderungenKunden: 0,
      },
    ],
    anlagevolumen: [
      {
        spareinlagen: 0,
        andereVerbindlichkeiten: 0,
        summeVerbindlichkeiten: 79,
      },
      {
        spareinlagen: 0,
        andereVerbindlichkeiten: 0,
        summeVerbindlichkeiten: 26,
      },
      {
        spareinlagen: 0,
        andereVerbindlichkeiten: 0,
        summeVerbindlichkeiten: 114,
      },
      {
        spareinlagen: 0,
        andereVerbindlichkeiten: 0,
        summeVerbindlichkeiten: 88,
      },
    ],
    provisionseinnahmen: [
      {
        provisionsaufwendungen: 0,
        provisionsertrag: 84.5,
        provisionsueberschuss: 0,
      },
      {
        provisionsaufwendungen: 0,
        provisionsertrag: 75.6,
        provisionsueberschuss: 0,
      },
    ],
    gewinn: [
      {
        jahr: 2021,
        gewinn: 236.3,
      },
      {
        jahr: 2020,
        gewinn: 234.5,
      },
      {
        jahr: 2019,
        gewinn: 250.2,
      },
      {
        jahr: 2018,
        gewinn: 235.5,
      },
    ],
  },
  {
    id: 274,
    anbieterLogo:
      'https://www.fmh.de/resources/assets/1190/wide/e4c6450e96efa7353e20c5a3c72e766c471b1f26-advanzia_bank_RGB (002).jpg',
    landname: 'Luxemburg',
    finanzdaten: [
      {
        jahr: 2022,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2021,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 383,
        marktkapitalisierung: null,
        bilanzsumme: 3126,
      },
      {
        jahr: 2020,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 304,
        marktkapitalisierung: null,
        bilanzsumme: 2650,
      },
      {
        jahr: 2019,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 237,
        marktkapitalisierung: null,
        bilanzsumme: 2554,
      },
      {
        jahr: 2018,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2017,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2016,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
    ],
    anbietername: 'Advanzia Bank',
    landkuerzel: 'LU',
    standortlaender: [
      'Luxemburg',
      'Deutschland',
      'Österreich',
      'Frankreich',
      'Spanien',
      'Italien',
    ],
    schwerpunkte: [''],
    bankratings: [
      {
        ratingagentur: 'S&P',
        rating: '',
      },
      {
        ratingagentur: 'Fitch',
        rating: '',
      },
    ],
    gesellschafter: [],
    tochtergesellschaften: null,
    filialen: null,
    gruendungsjahr: '2005',
    mitarbeiter: [
      {
        jahr: 2021,
        anzahl: '187',
      },
      {
        jahr: 2020,
        anzahl: '198',
      },
      {
        jahr: 2019,
        anzahl: '177',
      },
    ],
    gruendungsort: 'Munsbach',
    kernkapitalquote: null,
    eigenkapitalrendite: [
      {
        jahr: 2021,
        eigenkapitalrendite: 31.5,
      },
      {
        jahr: 2020,
        eigenkapitalrendite: 38.9,
      },
      {
        jahr: 2019,
        eigenkapitalrendite: 36.7,
      },
    ],
    gesamtkapitalquote: [
      {
        jahr: 2021,
        gesamtkapitalquote: 24.78,
      },
      {
        jahr: 2020,
        gesamtkapitalquote: 21.33,
      },
      {
        jahr: 2019,
        gesamtkapitalquote: 17.97,
      },
    ],
    leverageRatio: null,
    einlagensicherung: [
      {
        bezeichnung: 'Gesetzliche Einlagensicherung',
        infotext:
          'Gesetzliche Einlagensicherung bis 100.000 Euro durch die luxemburgische Einlagensicherung (www.agdl.lu).',
        landname: 'Luxemburg',
        betrag: 100000,
        betragZahlenname: 0,
        waehrung: 'EUR',
      },
    ],
    eigenkapital: [
      {
        jahr: 2021,
        eigenkapital: 383,
      },
      {
        jahr: 2020,
        eigenkapital: 304,
      },
      {
        jahr: 2019,
        eigenkapital: 237,
      },
    ],
    bilanzsumme: [
      {
        jahr: 2021,
        bilanzsumme: 3126,
      },
      {
        jahr: 2020,
        bilanzsumme: 2650,
      },
      {
        jahr: 2019,
        bilanzsumme: 2554,
      },
    ],
    boersennotiert: true,
    marktkapitalisierung: null,
    kreditvolumen: null,
    anlagevolumen: null,
    provisionseinnahmen: null,
    gewinn: null,
  },
  {
    id: 399,
    anbieterLogo:
      'https://www.fmh.de/resources/assets/1490/wide/952fb0119398b2f0c97d264d97bb526bc0573e1e-abbank-logo.jpg',
    landname: 'Griechenland',
    finanzdaten: [
      {
        jahr: 2022,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2021,
        waehrung: 'EUR',
        gewinn: 7,
        eigenkapital: 101,
        marktkapitalisierung: null,
        bilanzsumme: 1000,
      },
      {
        jahr: 2020,
        waehrung: 'EUR',
        gewinn: 4,
        eigenkapital: 95,
        marktkapitalisierung: null,
        bilanzsumme: 605,
      },
      {
        jahr: 2019,
        waehrung: 'EUR',
        gewinn: -0.6,
        eigenkapital: 89,
        marktkapitalisierung: null,
        bilanzsumme: 504,
      },
      {
        jahr: 2018,
        waehrung: 'EUR',
        gewinn: 0.4,
        eigenkapital: 89,
        marktkapitalisierung: null,
        bilanzsumme: 271,
      },
      {
        jahr: 2017,
        waehrung: 'EUR',
        gewinn: -1,
        eigenkapital: 90,
        marktkapitalisierung: null,
        bilanzsumme: 238,
      },
      {
        jahr: 2016,
        waehrung: 'EUR',
        gewinn: -0.9,
        eigenkapital: 92,
        marktkapitalisierung: null,
        bilanzsumme: 226,
      },
    ],
    anbietername: 'Aegean Baltic Bank',
    landkuerzel: 'GR',
    standortlaender: ['Griechenland'],
    schwerpunkte: [
      'Branchenunabhängige Unternehmensfinanzierung',
      'Corporate Banking und Finance',
      'Schifffahrtsbranche',
      'Treasury',
    ],
    bankratings: [
      {
        ratingagentur: 'S&P',
        rating: 'B/B',
      },
      {
        ratingagentur: 'Fitch',
        rating: '',
      },
    ],
    gesellschafter: [],
    tochtergesellschaften: null,
    filialen: [
      {
        jahr: 2022,
        anzahl: '3',
      },
    ],
    gruendungsjahr: '2002',
    mitarbeiter: [
      {
        jahr: 2021,
        anzahl: '102',
      },
      {
        jahr: 2020,
        anzahl: '95',
      },
      {
        jahr: 2019,
        anzahl: '95',
      },
      {
        jahr: 2018,
        anzahl: '82',
      },
      {
        jahr: 2017,
        anzahl: '81',
      },
    ],
    gruendungsort: 'Maroussi',
    kernkapitalquote: [
      {
        jahr: 2021,
        kernkapitalquote: 15.99,
      },
      {
        jahr: 2020,
        kernkapitalquote: 20.61,
      },
      {
        jahr: 2019,
        kernkapitalquote: 26.21,
      },
      {
        jahr: 2018,
        kernkapitalquote: 38.16,
      },
      {
        jahr: 2017,
        kernkapitalquote: 41.62,
      },
    ],
    eigenkapitalrendite: null,
    gesamtkapitalquote: [
      {
        jahr: 2021,
        gesamtkapitalquote: 15.99,
      },
      {
        jahr: 2020,
        gesamtkapitalquote: 20.61,
      },
      {
        jahr: 2019,
        gesamtkapitalquote: 26.21,
      },
      {
        jahr: 2018,
        gesamtkapitalquote: 38.16,
      },
      {
        jahr: 2017,
        gesamtkapitalquote: 41.62,
      },
    ],
    leverageRatio: null,
    einlagensicherung: [
      {
        bezeichnung: 'Gesetzliche Einlagensicherung',
        infotext:
          'Gesetzliche Einlagensicherung bis 100.000 Euro durch die griechische Einlagensicherung HDIGF (www.teke.gr).',
        landname: 'Griechenland',
        betrag: 100000,
        betragZahlenname: 0,
        waehrung: 'EUR',
      },
    ],
    eigenkapital: [
      {
        jahr: 2021,
        eigenkapital: 101,
      },
      {
        jahr: 2020,
        eigenkapital: 95,
      },
      {
        jahr: 2019,
        eigenkapital: 89,
      },
      {
        jahr: 2018,
        eigenkapital: 89,
      },
      {
        jahr: 2017,
        eigenkapital: 90,
      },
      {
        jahr: 2016,
        eigenkapital: 92,
      },
    ],
    bilanzsumme: [
      {
        jahr: 2021,
        bilanzsumme: 1000,
      },
      {
        jahr: 2020,
        bilanzsumme: 605,
      },
      {
        jahr: 2019,
        bilanzsumme: 504,
      },
      {
        jahr: 2018,
        bilanzsumme: 271,
      },
      {
        jahr: 2017,
        bilanzsumme: 238,
      },
      {
        jahr: 2016,
        bilanzsumme: 226,
      },
    ],
    boersennotiert: false,
    marktkapitalisierung: null,
    kreditvolumen: [
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 69,
        forderungenKunden: 566,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 89,
        forderungenKunden: 367,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 147,
        forderungenKunden: 242,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 62,
        forderungenKunden: 156,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 50,
        forderungenKunden: 138,
      },
    ],
    anlagevolumen: [
      {
        spareinlagen: 842,
        andereVerbindlichkeiten: 4,
        summeVerbindlichkeiten: 899,
      },
      {
        spareinlagen: 447,
        andereVerbindlichkeiten: 3,
        summeVerbindlichkeiten: 511,
      },
      {
        spareinlagen: 377,
        andereVerbindlichkeiten: 2,
        summeVerbindlichkeiten: 415,
      },
      {
        spareinlagen: 146,
        andereVerbindlichkeiten: 2,
        summeVerbindlichkeiten: 182,
      },
      {
        spareinlagen: 131,
        andereVerbindlichkeiten: 1,
        summeVerbindlichkeiten: 148,
      },
    ],
    provisionseinnahmen: [
      {
        provisionsaufwendungen: 0.4,
        provisionsertrag: 4,
        provisionsueberschuss: 4,
      },
      {
        provisionsaufwendungen: 0.4,
        provisionsertrag: 3,
        provisionsueberschuss: 3,
      },
      {
        provisionsaufwendungen: 0.4,
        provisionsertrag: 3,
        provisionsueberschuss: 3,
      },
      {
        provisionsaufwendungen: 0.3,
        provisionsertrag: 2,
        provisionsueberschuss: 2,
      },
      {
        provisionsaufwendungen: 0.2,
        provisionsertrag: 2,
        provisionsueberschuss: 2,
      },
    ],
    gewinn: [
      {
        jahr: 2021,
        gewinn: 7,
      },
      {
        jahr: 2020,
        gewinn: 4,
      },
      {
        jahr: 2019,
        gewinn: -0.6,
      },
      {
        jahr: 2018,
        gewinn: 0.4,
      },
      {
        jahr: 2017,
        gewinn: -1,
      },
      {
        jahr: 2016,
        gewinn: -0.9,
      },
    ],
  },
  {
    id: 491,
    anbieterLogo:
      'https://www.fmh.de/resources/assets/1673/wide/0dc1595587cc2d6150d9c0e374d1a50ab299ea47-Agram-Banca_120x40.jpg',
    landname: 'Kroatien',
    finanzdaten: [
      {
        jahr: 2022,
        waehrung: 'HRK',
        gewinn: null,
        eigenkapital: 440,
        marktkapitalisierung: null,
        bilanzsumme: 4212,
      },
      {
        jahr: 2022,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2021,
        waehrung: 'HRK',
        gewinn: 41,
        eigenkapital: 461,
        marktkapitalisierung: null,
        bilanzsumme: 4293,
      },
      {
        jahr: 2021,
        waehrung: 'EUR',
        gewinn: 5,
        eigenkapital: 61,
        marktkapitalisierung: null,
        bilanzsumme: 570,
      },
      {
        jahr: 2020,
        waehrung: 'HRK',
        gewinn: 28,
        eigenkapital: 429,
        marktkapitalisierung: null,
        bilanzsumme: 3801,
      },
      {
        jahr: 2020,
        waehrung: 'EUR',
        gewinn: 4,
        eigenkapital: 57,
        marktkapitalisierung: null,
        bilanzsumme: 504,
      },
      {
        jahr: 2019,
        waehrung: 'HRK',
        gewinn: 38,
        eigenkapital: 409,
        marktkapitalisierung: null,
        bilanzsumme: 3725,
      },
      {
        jahr: 2019,
        waehrung: 'EUR',
        gewinn: 5,
        eigenkapital: 55,
        marktkapitalisierung: null,
        bilanzsumme: 502,
      },
      {
        jahr: 2018,
        waehrung: 'HRK',
        gewinn: 27,
        eigenkapital: 353,
        marktkapitalisierung: null,
        bilanzsumme: 3447,
      },
      {
        jahr: 2018,
        waehrung: 'EUR',
        gewinn: 4,
        eigenkapital: 48,
        marktkapitalisierung: null,
        bilanzsumme: 465,
      },
      {
        jahr: 2017,
        waehrung: 'HRK',
        gewinn: 21,
        eigenkapital: 336,
        marktkapitalisierung: null,
        bilanzsumme: 3383,
      },
      {
        jahr: 2017,
        waehrung: 'EUR',
        gewinn: 3,
        eigenkapital: 45,
        marktkapitalisierung: null,
        bilanzsumme: 453,
      },
      {
        jahr: 2016,
        waehrung: 'HRK',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2016,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
    ],
    anbietername: 'Agram banka',
    landkuerzel: 'HR',
    standortlaender: ['Kroatien'],
    schwerpunkte: ['Einlagen', 'Kredite', 'Zahlungsverkehr'],
    bankratings: [
      {
        ratingagentur: 'S&P',
        rating: '',
      },
      {
        ratingagentur: 'Fitch',
        rating: '',
      },
    ],
    gesellschafter: [],
    tochtergesellschaften: null,
    filialen: [
      {
        jahr: 2021,
        anzahl: '23',
      },
      {
        jahr: 2020,
        anzahl: '24',
      },
      {
        jahr: 2019,
        anzahl: '24',
      },
      {
        jahr: 2018,
        anzahl: '24',
      },
      {
        jahr: 2017,
        anzahl: '24',
      },
    ],
    gruendungsjahr: '1994',
    mitarbeiter: [
      {
        jahr: 2021,
        anzahl: '188',
      },
      {
        jahr: 2020,
        anzahl: '198',
      },
      {
        jahr: 2019,
        anzahl: '195',
      },
      {
        jahr: 2018,
        anzahl: '189',
      },
      {
        jahr: 2017,
        anzahl: '193',
      },
    ],
    gruendungsort: 'Zagreb',
    kernkapitalquote: null,
    eigenkapitalrendite: [
      {
        jahr: 2020,
        eigenkapitalrendite: 6.72,
      },
    ],
    gesamtkapitalquote: null,
    leverageRatio: null,
    einlagensicherung: [
      {
        bezeichnung: 'Gesetzliche Einlagensicherung',
        infotext:
          'Gesetzliche Einlagensicherung bis 100.000 Euro durch State Agency for Deposit Insurance and Bank Resolution (www.dab.hr/en/home)',
        landname: 'Kroatien',
        betrag: 100000,
        betragZahlenname: 0,
        waehrung: 'EUR',
      },
    ],
    eigenkapital: [
      {
        jahr: 2022,
        eigenkapital: 440,
      },
      {
        jahr: 2021,
        eigenkapital: 461,
      },
      {
        jahr: 2020,
        eigenkapital: 429,
      },
      {
        jahr: 2019,
        eigenkapital: 409,
      },
      {
        jahr: 2018,
        eigenkapital: 353,
      },
      {
        jahr: 2017,
        eigenkapital: 336,
      },
    ],
    bilanzsumme: [
      {
        jahr: 2022,
        bilanzsumme: 4212,
      },
      {
        jahr: 2021,
        bilanzsumme: 4293,
      },
      {
        jahr: 2020,
        bilanzsumme: 3801,
      },
      {
        jahr: 2019,
        bilanzsumme: 3725,
      },
      {
        jahr: 2018,
        bilanzsumme: 3447,
      },
      {
        jahr: 2017,
        bilanzsumme: 3383,
      },
    ],
    boersennotiert: false,
    marktkapitalisierung: null,
    kreditvolumen: [
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 0,
        forderungenKunden: 2182,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 0,
        forderungenKunden: 2161,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 0,
        forderungenKunden: 2036,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 0,
        forderungenKunden: 2092,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 0,
        forderungenKunden: 1880,
      },
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 0,
        forderungenKunden: 1770,
      },
    ],
    anlagevolumen: [
      {
        spareinlagen: 0,
        andereVerbindlichkeiten: 59,
        summeVerbindlichkeiten: 3773,
      },
      {
        spareinlagen: 3121,
        andereVerbindlichkeiten: 60,
        summeVerbindlichkeiten: 3832,
      },
      {
        spareinlagen: 2562,
        andereVerbindlichkeiten: 44,
        summeVerbindlichkeiten: 3372,
      },
      {
        spareinlagen: 2479,
        andereVerbindlichkeiten: 53,
        summeVerbindlichkeiten: 3316,
      },
      {
        spareinlagen: 2387,
        andereVerbindlichkeiten: 29,
        summeVerbindlichkeiten: 3093,
      },
      {
        spareinlagen: 2315,
        andereVerbindlichkeiten: 18,
        summeVerbindlichkeiten: 3047,
      },
    ],
    provisionseinnahmen: [
      {
        provisionsaufwendungen: 2,
        provisionsertrag: 8,
        provisionsueberschuss: 0,
      },
      {
        provisionsaufwendungen: 9,
        provisionsertrag: 32,
        provisionsueberschuss: 23,
      },
      {
        provisionsaufwendungen: 9,
        provisionsertrag: 29,
        provisionsueberschuss: 20,
      },
      {
        provisionsaufwendungen: 9,
        provisionsertrag: 28,
        provisionsueberschuss: 19,
      },
      {
        provisionsaufwendungen: 8,
        provisionsertrag: 30,
        provisionsueberschuss: 23,
      },
      {
        provisionsaufwendungen: 8,
        provisionsertrag: 27,
        provisionsueberschuss: 19,
      },
    ],
    gewinn: [
      {
        jahr: 2021,
        gewinn: 41,
      },
      {
        jahr: 2020,
        gewinn: 28,
      },
      {
        jahr: 2019,
        gewinn: 38,
      },
      {
        jahr: 2018,
        gewinn: 27,
      },
      {
        jahr: 2017,
        gewinn: 21,
      },
    ],
  },
  {
    id: 379,
    anbieterLogo:
      'https://www.fmh.de/resources/assets/1462/wide/0ddd3d97b8d3b66982468b276bc591ddbec017a4-aion-logo.jpg',
    landname: 'Belgien',
    finanzdaten: [
      {
        jahr: 2022,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2021,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2020,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 87,
        marktkapitalisierung: null,
        bilanzsumme: 1158,
      },
      {
        jahr: 2019,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 85,
        marktkapitalisierung: null,
        bilanzsumme: 1100,
      },
      {
        jahr: 2018,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2017,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2016,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
    ],
    anbietername: 'Aion Bank',
    landkuerzel: 'BE',
    standortlaender: ['Polen', 'Deutschland', 'Schweden', 'Belgien'],
    schwerpunkte: ['Einlagengeschäft'],
    bankratings: [
      {
        ratingagentur: 'S&P',
        rating: '',
      },
      {
        ratingagentur: 'Fitch',
        rating: '',
      },
    ],
    gesellschafter: [],
    tochtergesellschaften: null,
    filialen: [
      {
        jahr: 2022,
        anzahl: '2',
      },
    ],
    gruendungsjahr: '1947',
    mitarbeiter: [
      {
        jahr: 2022,
        anzahl: '233',
      },
    ],
    gruendungsort: 'Brüssel',
    kernkapitalquote: [
      {
        jahr: 2019,
        kernkapitalquote: 16.9,
      },
      {
        jahr: 2018,
        kernkapitalquote: 15.1,
      },
    ],
    eigenkapitalrendite: null,
    gesamtkapitalquote: [
      {
        jahr: 2021,
        gesamtkapitalquote: 21.6,
      },
      {
        jahr: 2020,
        gesamtkapitalquote: 13.76,
      },
      {
        jahr: 2019,
        gesamtkapitalquote: 19.73,
      },
    ],
    leverageRatio: null,
    einlagensicherung: [
      {
        bezeichnung: 'Gesetzliche Einlagensicherung',
        infotext:
          'Gesetzliche Einlagensicherung bis 100.000 Euro durch den belgischen Sicherungsfonds für Einlagen und Finanzinstrumente.',
        landname: 'Belgien',
        betrag: 100000,
        betragZahlenname: 0,
        waehrung: 'EUR',
      },
    ],
    eigenkapital: [
      {
        jahr: 2020,
        eigenkapital: 87,
      },
      {
        jahr: 2019,
        eigenkapital: 85,
      },
    ],
    bilanzsumme: [
      {
        jahr: 2020,
        bilanzsumme: 1158,
      },
      {
        jahr: 2019,
        bilanzsumme: 1100,
      },
    ],
    boersennotiert: false,
    marktkapitalisierung: null,
    kreditvolumen: [
      {
        grundpfandrecht: 0,
        forderungenKreditinstitute: 0,
        forderungenKunden: 0,
      },
    ],
    anlagevolumen: null,
    provisionseinnahmen: null,
    gewinn: null,
  },
  {
    id: 260,
    anbieterLogo:
      'https://www.fmh.de/resources/assets/1191/wide/92e2bd8d82320991b094c4508dd37b06e89e95ad-Akbank 705x235.jpg',
    landname: 'Deutschland',
    finanzdaten: [
      {
        jahr: 2022,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2021,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 819,
        marktkapitalisierung: 36970,
        bilanzsumme: null,
      },
      {
        jahr: 2020,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 776,
        marktkapitalisierung: null,
        bilanzsumme: 3708,
      },
      {
        jahr: 2019,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 724,
        marktkapitalisierung: null,
        bilanzsumme: 4404,
      },
      {
        jahr: 2018,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2017,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2016,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
    ],
    anbietername: 'Akbank',
    landkuerzel: 'DE',
    standortlaender: ['Deutschland'],
    schwerpunkte: [
      'Firmen- und Privatkundengeschäft',
      'Geschäftskundengeschäft',
      'Investmentbanking',
      'KMU-Banking',
      'Private Banking',
      'Privatkundengeschäft',
      'Treasury-Transaktionen',
      'Versicherungsgeschäft',
      'Zahlungssysteme',
    ],
    bankratings: [
      {
        ratingagentur: 'S&P',
        rating: '',
      },
      {
        ratingagentur: 'Fitch',
        rating: 'B+',
      },
    ],
    gesellschafter: [],
    tochtergesellschaften: null,
    filialen: null,
    gruendungsjahr: '1998',
    mitarbeiter: [
      {
        jahr: 2021,
        anzahl: '63',
      },
      {
        jahr: 2020,
        anzahl: '52',
      },
      {
        jahr: 2019,
        anzahl: '51',
      },
      {
        jahr: 2018,
        anzahl: '49',
      },
      {
        jahr: 2017,
        anzahl: '48',
      },
      {
        jahr: 2016,
        anzahl: '45',
      },
    ],
    gruendungsort: 'Frankfurt am Main',
    kernkapitalquote: [
      {
        jahr: 2020,
        kernkapitalquote: 4.78,
      },
      {
        jahr: 2019,
        kernkapitalquote: 6.09,
      },
      {
        jahr: 2018,
        kernkapitalquote: 6.69,
      },
      {
        jahr: 2017,
        kernkapitalquote: 8,
      },
      {
        jahr: 2016,
        kernkapitalquote: 9.47,
      },
    ],
    eigenkapitalrendite: [
      {
        jahr: 2020,
        eigenkapitalrendite: 6.46,
      },
      {
        jahr: 2019,
        eigenkapitalrendite: 7.24,
      },
      {
        jahr: 2018,
        eigenkapitalrendite: 6.66,
      },
    ],
    gesamtkapitalquote: [
      {
        jahr: 2020,
        gesamtkapitalquote: 26.5,
      },
      {
        jahr: 2019,
        gesamtkapitalquote: 19.6,
      },
      {
        jahr: 2018,
        gesamtkapitalquote: 17.6,
      },
      {
        jahr: 2017,
        gesamtkapitalquote: 14.1,
      },
    ],
    leverageRatio: null,
    einlagensicherung: [
      {
        bezeichnung: 'Erweiterte Einlagensicherung',
        infotext:
          'Zusätzlich besteht eine erweiterte Absicherung in Millionenhöhe durch den Einlagensicherungsfonds des Bundesverbands deutscher Banken – BdB (www.bdb.de).',
        landname: 'Deutschland',
        betrag: 108.44,
        betragZahlenname: 2,
        waehrung: 'EUR',
      },
    ],
    eigenkapital: [
      {
        jahr: 2021,
        eigenkapital: 819,
      },
      {
        jahr: 2020,
        eigenkapital: 776,
      },
      {
        jahr: 2019,
        eigenkapital: 724,
      },
    ],
    bilanzsumme: [
      {
        jahr: 2020,
        bilanzsumme: 3708,
      },
      {
        jahr: 2019,
        bilanzsumme: 4404,
      },
    ],
    boersennotiert: true,
    marktkapitalisierung: [
      {
        jahr: 2021,
        marktkapitalisierung: 36970,
      },
    ],
    kreditvolumen: null,
    anlagevolumen: null,
    provisionseinnahmen: null,
    gewinn: null,
  },
  {
    id: 158,
    anbieterLogo:
      'https://www.fmh.de/resources/assets/1192/wide/b6bb380ce41972e8e2a4630d053edbedf9fd8ece-akf-bank-logo.jpg',
    landname: 'Deutschland',
    finanzdaten: [
      {
        jahr: 2022,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2021,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2020,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 183,
        marktkapitalisierung: null,
        bilanzsumme: 2284,
      },
      {
        jahr: 2019,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: 175,
        marktkapitalisierung: null,
        bilanzsumme: 2308,
      },
      {
        jahr: 2018,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2017,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
      {
        jahr: 2016,
        waehrung: 'EUR',
        gewinn: null,
        eigenkapital: null,
        marktkapitalisierung: null,
        bilanzsumme: null,
      },
    ],
    anbietername: 'akf bank',
    landkuerzel: 'DE',
    standortlaender: ['Deutschland'],
    schwerpunkte: [''],
    bankratings: [
      {
        ratingagentur: 'S&P',
        rating: '',
      },
      {
        ratingagentur: 'Fitch',
        rating: '',
      },
    ],
    gesellschafter: [],
    tochtergesellschaften: null,
    filialen: null,
    gruendungsjahr: '1968',
    mitarbeiter: [
      {
        jahr: 2020,
        anzahl: '390',
      },
      {
        jahr: 2019,
        anzahl: '376',
      },
    ],
    gruendungsort: 'Wuppertal',
    kernkapitalquote: [
      {
        jahr: 2020,
        kernkapitalquote: 10.93,
      },
      {
        jahr: 2019,
        kernkapitalquote: 10.15,
      },
    ],
    eigenkapitalrendite: null,
    gesamtkapitalquote: [
      {
        jahr: 2020,
        gesamtkapitalquote: 14.69,
      },
      {
        jahr: 2019,
        gesamtkapitalquote: 13.82,
      },
    ],
    leverageRatio: [
      {
        jahr: 2020,
        leverageRatio: 7.46,
      },
      {
        jahr: 2019,
        leverageRatio: 6.89,
      },
    ],
    einlagensicherung: [
      {
        bezeichnung: 'Gesetzliche Einlagensicherung',
        infotext:
          'Gesetzliche Einlagensicherung bis 100.000 Euro durch die Entschädigungseinrichtung deutscher Banken - EdB (www.edb-banken.de).',
        landname: 'Deutschland',
        betrag: 100000,
        betragZahlenname: 0,
        waehrung: 'EUR',
      },
    ],
    eigenkapital: [
      {
        jahr: 2020,
        eigenkapital: 183,
      },
      {
        jahr: 2019,
        eigenkapital: 175,
      },
    ],
    bilanzsumme: [
      {
        jahr: 2020,
        bilanzsumme: 2284,
      },
      {
        jahr: 2019,
        bilanzsumme: 2308,
      },
    ],
    boersennotiert: null,
    marktkapitalisierung: null,
    kreditvolumen: null,
    anlagevolumen: null,
    provisionseinnahmen: null,
    gewinn: null,
  },
];
export const EXTENSIONS = {
  'application/pdf': '.pdf',
  'application/ms-excel': '.xls',
  'text/csv': '.csv',
};
export const DEFAULT_STR_OPTIONS = {
  standortlaender: [
    'Deutschland',
    'Algerien',
    'Angola',
    'Argentinien',
    'Australien',
    'Ägypten',
    'Äthiopien',
    'Bahrain',
    'Bangladesch',
    'Belarus',
    'Belgien',
    'Bosnien & Herzegowina',
    'Brasilien',
    'Bulgarien',
    'Burkina Faso',
    'Chile',
    'China',
    'Dänemark',
    'Deutschland (Berlin)',
    'Dschibuti',
    'Elfenbeinküste',
    'England',
    'Estland',
    'Finnland',
    'Frankreich',
    'Georgien',
    'Ghana',
    'Griechenland',
    'Großbritannien',
    'Guinea',
    'Idien',
    'Indien',
    'Indonesien',
    'Irak',
    'Irland',
    'Israel',
    'Italien',
    'Japan',
    'Jemen',
    'Jordanien',
    'Kanada',
    'Kasachstan',
    'Kenia',
    'Kroatien',
    'Kuwait',
    'Lettland',
    'Libanon',
    'Libyen',
    'Litauen',
    'Luxemburg',
    'Malaysia',
    'Mali',
    'Malta',
    'Marokko',
    'Mauretanien',
    'Mexiko',
    'Montenegro',
    'Mosambik',
    'Namibia',
    'Neuseeland',
    'Niederlande',
    'Niederlande (Amsterdam)',
    'Nigeria',
    'Norwegen',
    'Oman',
    'Österreich',
    'Pakistan',
    'Panama',
    'Philippinen',
    'Polen',
    'Portugal',
    'Rumänien',
    'Russland',
    'Saudi Arabien',
    'Schweden',
    'Schweiz',
    'Senegal',
    'Serbien',
    'Singapur',
    'Slowakei',
    'Slowenien',
    'Spanien',
    'Sri Lanka',
    'Südafrika',
    'Sudan',
    'Südkorea',
    'Taiwan',
    'Thailand',
    'Tschad',
    'Tschechien',
    'Tschechische Republik',
    'Tunesien',
    'Türkei',
    'Turkmenistan',
    'Uganda',
    'Ukraine',
    'Ungarn',
    'Usbekistan',
    'USA',
    'Venezuela',
    'Vereinigte Arabische Emirate',
    'Vereinigtes Königreich',
    'Vietnam',
    'Zypern',
  ],
  schwerpunkte: [
    'Absatzfinanzierung',
    'Absicherung biometrischer Risiken',
    'Agrarindustrie',
    'Akkreditive',
    'Altersvorsorge',
    'Altersvorsorge und Versicherungen',
    'Ankauf von Leasing-, Mietkauf- und Factoringforderungen der abcfinance GmbH',
    'Ankauf von Leasingforderungen',
    'Anlage',
    'Anlagemanagement',
    'Anlageprodukte',
    'Anlagevermittlung',
    'Anleihen',
    'Anschlussfinanzierung',
    'Asset Management',
    'Ausleihen',
    'Ausstellung von Garantien',
    'Auszahlplan',
    'Autokredite',
    'Autoleasing',
    'Autoleasing/Operatingleasing',
    'Bank- und Finanzdienstleistungen',
    'Bank- und Kreditkarten',
    'Bank- und Zahlungsdienstleistungen',
    'Bankdienstleistungen',
    'Bankgarantien',
    'Banking für Geschäftskunden und Unternehmen',
    'Bankkarten',
    'Bankleistungen für Unternehmen und institutionelle Einrichtungen',
    'Bargeld- und Abwicklungsgeschäfte',
    'Bargeldloser Zahlungsverkehr',
    'Bau und sonstige Entwicklung von Immobilien',
    'Baudarlehen',
    'Baufinanzierung',
    'Baufinanzierung und Bausparen',
    'Baufinanzierung und Immobilien',
    'Baufinanzierung, Bausparen',
    'Baugewerbe',
    'Bauspar- und Baufinanzierungsgeschäft',
    'Bausparen',
    'Bauspargeschäft',
    'Bausparvertrag',
    'Beratung',
    'Beratung Finanzbedarf',
    'Beratung Immobilienkauf',
    'Beratung: Kredite, Festgeld',
    'Betriebsmittelfinanzierung',
    'Betriebsmittelkredite (KMU)',
    'Bezahllösungen',
    'Beziehungsbank',
    'Blankodarlehen',
    'Branchenunabhängige Unternehmensfinanzierung',
    'Brokerage',
    'Brokerage-Dienstleistungen',
    'Cash flow management',
    'Corporate Banking',
    'Corporate Banking und Finance',
    'Darlehen',
    'Darlehen und Leasing',
    'Darlehen/Kredite',
    'Datendienste',
    'Debitkarten',
    'Devisendienstleistungen',
    'Devisengeschäft',
    'Digital Banking',
    'Digital Banking/Payments',
    'Digitale Bankdienstleistungen',
    'Direktbanking',
    'Direktkredite für Endverbraucher',
    'Direktverkauf',
    'E-commerce',
    'E-Commerce',
    'E-Credit',
    'E-finance',
    'E-Finance',
    'Ecommerce-Lösungen mit digitalen Zahlungslösungen für Online-Händler',
    'Einlagen',
    'Einlagen und Girokonten',
    'Einlagengeschäft',
    'Einlagenkonten',
    'Einlagensicherung',
    'Einzel- und Großhandel',
    'Einzelhandel und Unternehmen',
    'Ersparnisse',
    'Erwerb von Grundstücken',
    'Export- und Import-Finanzierung',
    'Exportfinanzierung',
    'Factoring',
    'Factoring und Leasing',
    'Factoring und Unternehmenskredite',
    'Factoring- und Kreditmanagement',
    'Fahrzeugfinanzierung',
    'Festgeld',
    'Filialgeschäft',
    'Financial Services',
    'Finanz- und Versicherungsdienstleistungen',
    'Finanzdienstleistungen',
    'Finanzdienstleistungen Privatkunden',
    'Finanzierung',
    'Finanzierung des internationalen Handels',
    'Finanzierung v. Unternehmenskunden',
    'Finanzierung von Baugrundstücken',
    'Finanzierung von Gewerbeimmobilien',
    'Finanzierung von privatem Wohneigentum',
    'Finanzierung von Projekten, die im Rahmen der operationellen Programme der EU durchgeführt werden',
    'Finanzierung von Umweltprojekten',
    'Finanzierung von Wohnimmobilien',
    'Finanzierungen',
    'Finanzierungen der öffentlichen Hand',
    'Finanzierungsleistungen',
    'Finanzierungslösungen für Handelstransaktionen',
    'Finanzierungsprogramm KMU',
    'Firmen- und Privateinlagen',
    'Firmen- und Privatkundengeschäft',
    'Firmenkredite',
    'Firmenkunden und Investment Banking',
    'Firmenkundengeschäft',
    'Firmenkundengeschäft und Investmentbanking',
    'Firmenkundenkredite, gewerbliche Immobilienfinanzierung',
    'Fondsberatung',
    'Fondsvermittlung',
    'Forward-Darlehen',
    'Fracht',
    'Fremd- und Eigenkapialfinanzierung',
    'Gebrauchtmaschinenfinanzierung',
    'Geförderte Finanzierungen',
    'Geldanlage',
    'Geldanlage, Versicherungen',
    'Geldanlagen',
    'Genehmigungsraten',
    'Geräteleasing',
    'Geschäfte mit Wertpapieren und Devisen',
    'Geschäftskonten',
    'Geschäftskunden',
    'Geschäftskundenbanking',
    'Geschäftskundengeschäft',
    'gewerbliches Immobilienfinanzierungsgeschäft',
    'Giro & Karten',
    'Giro- und Spareinlagengeschäft, Bauspar- und Baufinanzierungsprodukte, Privatkredite',
    'Girokonten',
    'Girokonten und Kreditkarten',
    'Girokonto',
    'Girokonto & Karten',
    'Groß- und Einzelhandel',
    'Handel',
    'Handel und Rohstofffinanzierung',
    'Handelsfinanzierung',
    'Handelswarenfinanzierung',
    'Hauptversicherungen',
    'Haushaltsfinanzen',
    'Haushypothek',
    'Händlereinkaufsfinanzierung',
    'Hypotheken',
    'Hypotheken und Darlehen',
    'Hypothekendarlehen',
    'Immobilendarlehen',
    'Immobilien',
    'Immobiliendarlehen',
    'Immobilienfinanzierung',
    'Immobilienkredite',
    'Immobilienverkaufsberatung',
    'Immobilienvermietung',
    'Import und Export',
    'Individuelle Produkte und Vermögensschutz',
    'Innovative Banktechnologien',
    'Institutional Banking',
    'internationale Einlagen',
    'Internationale Handelsfinanzierung',
    'Internetkredite',
    'Investitionen',
    'Investitionsdienstleistungen',
    'Investitionskreditgeschäft',
    'Investitionsprodukte',
    'Investment',
    'Investment & Brokerage Dienstleistungen',
    'Investmentbank',
    'Investmentbanking',
    'Investmentfonds',
    'IT-Lösungen',
    'Kapitalanlagen in Form von Festgeldverträgen und Prämien-/Entnahmedepots',
    'Kapitalisierungsgeschäfte',
    'Kapitalmärkte',
    'Karten',
    'Kartenausstellung',
    'Kartengeschäft',
    'Kartenprodukte',
    'Kleine und mittlere Unternehmen',
    'Kleinkredite',
    'KMU-Banking',
    'Kommerzielle Bankgeschäfte im Privatkundengeschäft',
    'Kommerzielle Kredite',
    'Konsumentenkredite',
    'Konten',
    'Konten für Privatkunden und KMUs',
    'Konten und Karten',
    'Kredit- und Debitkarten',
    'Kredit- und Einlagengeschäft',
    'Kredite',
    'Kredite & Finanzierung',
    'Kredite an Unternehmen',
    'Kredite für Freiberufler',
    'Kredite für Kleine und mittlere Unternehmen',
    'Kredite für kleine und mittlere Unternehmen und Privatpersonen',
    'Kredite für KMU',
    'Kredite für private Marktteilnehmer',
    'Kredite und Baufinanzierung',
    'Kredite und Darlehen',
    'Kredite und Finanzierung',
    'Kredite und Finanzierungen',
    'Kreditgeschäft',
    'Kreditkarten',
    'Kreditlösungen',
    'Kreditprodukte',
    'Kreditvergabe',
    'Kreditvergabe und Bankdienstleistungen',
    'Kundenkontoführung',
    'Kundenkredite',
    'Kurz- und mittelfristige Darlehen für Industrie- und Handelskunden',
    'Landwirtschaft, Forstwirtschaft und Fischerei',
    'langfristige Darlehen für Unternehmen in der EU',
    'Leasing',
    'Lebensversicherung',
    'Lebensversicherungen',
    'Leistungen für Unternehmenskunden und vermögende Privatpersonen',
    'Logistik',
    'Lösung für private Finanzplanung',
    'Management der Finanzströme',
    'Mietkaufgeschäft',
    'Mikrokredite',
    'Mittelfristige Darlehen an KMU, garantiert durch die italienische Regierung',
    'Mittelstandsfinanzierung',
    'Modernisierungskredite',
    'Nachhaltigkeit',
    'Online- und Mobile-Banking',
    'Online-Banking',
    'Onlinebanking',
    'Öffentliche Kunden',
    'Partnerbanking',
    'Payment',
    'Payment-Lösungen',
    'Peer-to-Peer-Kredite',
    'Point-of-Sale-Finanzierung',
    'Point-of-Sales-Finanzierung',
    'Privat- und Unternehmenskredite',
    'Privatdarlehen',
    'Private Banking',
    'Private Kapitalanlagen',
    'Private und institutionelle Einlagen',
    'Privates Banking',
    'Privatkonten',
    'Privatkonten und Sparangebote',
    'Privatkredite',
    'Privatkunden',
    'Privatkundenbank',
    'Privatkundengeschäft',
    'Projektfinanzierungen für Unternehmen',
    'Provisionsgeschäft',
    'Ratenkredite',
    'Ratenkreditgeschäft',
    'Refinanzierung',
    'Retail Banking',
    'Schifffahrtsbranche',
    'Schuldenverschreibung',
    'SME-Kreditgeschäft',
    'SMEs Banking',
    'Social Shopping',
    'Spar-Management',
    'Sparbank',
    'Sparen und Anlegen',
    'Sparen und Geldanlage',
    'Sparkonten',
    'Sparkonto',
    'Sparprodukte',
    'Spezialdarlehen',
    'Tagesgeld',
    'Tägliches Bankgeschäft',
    'Termineinlagen',
    'Trade Finance',
    'Treasury',
    'Treasury-Transaktionen',
    'Umfangreiche Finanzdienstleistungen',
    'Umschuldung von Krediten',
    'Ungesicherte Kredite',
    'Unternehmensberatung',
    'Unternehmensdarlehen',
    'Unternehmensderivate',
    'Unternehmensfinanzierung',
    'Unternehmenskredite',
    'Unternehmenskredite für KMU',
    'Unternehmerbank',
    'Unternehmerkundengeschäft',
    'Verantwortungsvolles Banking',
    'Verarbeitendes Gewerbe',
    'Verbraucherdarlehen',
    'Verbraucherdarlehen mit Schwerpunkt auf ungesicherten Krediten',
    'Verbraucherfinanzierung',
    'Verbraucherkredite',
    'Vergabe von Krediten an Mitglieder',
    'Vermittlung von Immobilien',
    'Vermittlung von Versicherungs- und Bausparverträge',
    'Vermögensanlage',
    'Vermögensverwaltung',
    'Verrechnungskonten',
    'Versicherung',
    'Versicherung & Service',
    'Versicherungen',
    'Versicherungen und Altersvorsorge',
    'Versicherungsgeschäft',
    'Versicherungsleistungen',
    'Versicherungsschutz im In- und Ausland',
    'Versicherungsvermittlung',
    'Verwaltung von Geldern',
    'Verwaltung von Kreditportfolios',
    'Vielfältiges Finanzangebot für Institutionen',
    'Vielfältiges Finanzangebot für Privatkunden',
    'Vorsorge',
    'Vorsorge & Versicherungen',
    'Vorsorge und Versicherung',
    'Vorsorgen & Versichern',
    'Vorsorgen und Versichern',
    'Wertebasierter Dienstleister',
    'Wertpapier- und Depotgeschäft',
    'Wertpapierdepots',
    'Wertpapiergeschäft',
    'Wertpapierhandel',
    'Wholesale Banking',
    'Zahlungen',
    'Zahlungs- und Transaktionsdienstleistungen',
    'Zahlungslösungen',
    'Zahlungslösungen für E-Commerce und Einzelhandelsketten',
    'Zahlungssysteme',
    'Zahlungsverkehr',
    'Zentralregulierung mit Delkredereübernahme im Einzel- und Großhandel',
    'Zentralregulierung ohne Delkredereübernahme im Einzel- und Großhandel',
    'Zusatzversicherungen',
  ],
};
export const DACH_COUNTRIES = ['Deutschland', 'Österreich', 'Schweiz'];
export const EU_COUNTRIES = [
  'Belgien',
  'Deutschland',
  'Frankreich',
  'Italien',
  'Luxemburg',
  'Niederlande',
  'Dänemark',
  'Irland',
  'Griechenland',
  'Portugal',
  'Spanien',
  'Finnland',
  'Österreich',
  'Schweden',
  'Estland',
  'Lettland',
  'Litauen',
  'Malta',
  'Polen',
  'Slowakei',
  'Slowenien',
  'Tschechien',
  'Ungarn',
  'Zypern',
  'Bulgarien',
  'Rumänien',
  'Kroatien',
];
export const EUROPA_COUNTRIES = [
  'Albanien',
  'Andorra',
  'Belarus',
  'Belgien',
  'Bosnien und Herzegowina',
  'Bulgarien',
  'Dänemark',
  'Deutschland',
  'Estland',
  'Finnland',
  'Frankreich',
  'Griechenland',
  'Irland',
  'Island',
  'Italien',
  'Kasachstan',
  'Kosovo',
  'Kroatien',
  'Lettland',
  'Liechtenstein',
  'Litauen',
  'Luxemburg',
  'Malta',
  'Moldau',
  'Monaco',
  'Montenegro',
  'Niederlande',
  'Nordmazedonien',
  'Norwegen',
  'Österreich',
  'Polen',
  'Portugal',
  'Rumänien',
  'Russland',
  'San Marino',
  'Schweden',
  'Schweiz',
  'Serbien',
  'Slowakei',
  'Slowenien',
  'Spanien',
  'Tschechien',
  'Türkei',
  'Ukraine',
  'Ungarn',
  'Vatikanstadt',
  'Vereinigtes Königreich',
];
export const BENELUX_COUNTRIES = ['Belgien', 'Niederlande', 'Luxemburg'];
export const BRICS_COUNTRIES = [
  'Brasilien',
  'Russland',
  'Indien',
  'China',
  'Südafrika',
];
export const AFRICA_COUNTRIES = [
  'Ägypten',
  'Algerien',
  'Angola',
  'Äquatorialguinea',
  'Äthiopien',
  'Benin',
  'Botswana',
  'Burkina Faso',
  'Burundi',
  'Dschibuti',
  'Elfenbeinküste',
  'Eritrea',
  'Eswatini',
  'Gabun',
  'Gambia',
  'Ghana',
  'Guinea',
  'Guinea-Bissau',
  'Kamerun',
  'Kap Verde',
  'Kenia',
  'Komoren',
  'Kongo, Demokratische Republik',
  'Kongo, Republik',
  'Lesotho',
  'Liberia',
  'Libyen',
  'Madagaskar',
  'Malawi',
  'Mali',
  'Marokko',
  'Mauretanien',
  'Mauritius',
  'Mosambik',
  'Namibia',
  'Niger',
  'Nigeria',
  'Ruanda',
  'Sambia',
  'São Tomé und Príncipe',
  'Senegal',
  'Seychellen',
  'Sierra Leone',
  'Simbabwe',
  'Somalia',
  'Südafrika',
  'Sudan',
  'Südsudan',
  'Tansania',
  'Togo',
  'Tschad',
  'Tunesien',
  'Uganda',
  'Zentralafrikanische Republik',
];
export const ASIAN_COUNTRIES = [
  'Afghanistan',
  'Ägypten',
  'Armenien',
  'Aserbaidschan',
  'Bahrain',
  'Bangladesch',
  'Bhutan',
  'Brunei',
  'Volksrepublik China',
  'Georgien',
  'Indien',
  'Indonesien',
  'Irak',
  'Iran',
  'Israel',
  'Japan',
  'Jemen',
  'Jordanien',
  'Kambodscha',
  'Kasachstan',
  'Katar',
  'Kirgisistan',
  'Nordkorea',
  'Südkorea',
  'Kuwait',
  'Laos',
  'Libanon',
  'Malaysia',
  'Malediven',
  'Mongolei',
  'Myanmar',
  'Nepal',
  'Oman',
  'Pakistan',
  'Philippinen',
  'Russland',
  'Saudi-Arabien',
  'Singapur',
  'Sri Lanka',
  'Syrien',
  'Tadschikistan',
  'Thailand',
  'Türkei',
  'Turkmenistan',
  'Usbekistan',
  'Vereinigte Arabische Emirate',
  'Vietnam',
  'Zypern',
];
export const AUSTRALIA_AND_OCEANIA_COUNTRIES = [
  'Australien',
  'Cookinseln',
  'Fidschi',
  'Kiribati',
  'Marshallinseln',
  'Mikronesien',
  'Nauru',
  'Neuseeland',
  'Palau',
  'Salomonen',
  'Samoa',
  'Tonga',
  'Tuvalu',
  'Vanuatu',
  'Amerikanisch-Samoa',
  'Bougainville',
  'Französisch-Polynesien',
  'Guam',
  'Neukaledonien',
  'Niue',
  'Nördliche Marianen',
  'Norfolkinsel',
  'Pitcairninseln',
  'Tokelau',
  'Wallis und Futuna',
  'United States Minor Outlying Islands',
];
export const AMERICAN_COUNTRIES = [
  'Antigua und Barbuda',
  'Argentinien',
  'Bahamas',
  'Barbados',
  'Belize',
  'Bolivien',
  'Brasilien',
  'Chile',
  'Costa Rica',
  'Dominica',
  'Dominikanische Republik',
  'El Salvador',
  'Ecuador',
  'Grenada',
  'Guatemala',
  'Guyana',
  'Haiti',
  'Honduras',
  'Jamaika',
  'Kanada',
  'Kolumbien',
  'Kuba',
  'Mexiko',
  'Nicaragua',
  'Panama',
  'Paraguay',
  'Peru',
  'St. Kitts und Nevis',
  'St. Lucia',
  'St. Vincent und die Grenadinen',
  'Suriname',
  'Trinidad und Tobago',
  'Uruguay',
  'Venezuela',
  'USA',
];

export const WORLD_COUNTRIES = {
  dach: DACH_COUNTRIES,
  europa: EUROPA_COUNTRIES,
  eu: EU_COUNTRIES,
  benelux: BENELUX_COUNTRIES,
  brics: BRICS_COUNTRIES,
  afrika: AFRICA_COUNTRIES,
  asien: ASIAN_COUNTRIES,
  'australien und ozeanien': AUSTRALIA_AND_OCEANIA_COUNTRIES,
  amerikas: AMERICAN_COUNTRIES,
};

export const MODEL_FIELDS = {
  zeitraum: [],
  region: ['dach'],
  boersennotiert: false,
  schwerpunkte: [],
  standort: WORLD_COUNTRIES['dach'],
};

export const INTEREST_OPTIONS = [
  { text: 'Tagesgeld', value: 'tagesgeld' },
  { text: 'Festgeld (kurz)', value: 'festgeldKurz' },
  { text: 'Festgeld (lang)', value: 'festgeldSparbrief' },
  { text: 'Sparbuch', value: 'sparbuch' },
  { text: 'Hypothekenzinsen', value: 'hypothekenzinsen' },
  { text: 'Ratenkredit 10 T €', value: 'ratenkredit' },
  { text: 'Giro', value: 'giro' },
  { text: 'Euribor', value: 'euribor' },
  { text: 'EONIA / ESTER', value: 'eonia' },
  { text: 'EZB-Leitzins', value: 'ezb' },
  { text: 'Inflation', value: 'inflation' },
  { text: 'Umlaufrendite', value: 'umlaufrendite' },
  { text: 'Bundesanleihe 10 Jahre', value: 'ba_10' },
  { text: 'Pfandbriefe (lt. Bundesbank)', value: 'pfandbriefe' },
];

export const SUB_INTEREST_OPTIONS = {
  pfandbriefe: [
    { text: '1 Jahr', value: 'pb_1' },
    { text: '5 Jahre', value: 'pb_5' },
    { text: '10 Jahre', value: 'pb_10' },
  ],
  tagesgeld: [
    { text: 'Basiszins', value: 'tg_basis' },
    { text: '5.000 €', value: 'tg_5000' },
    { text: '50.000 €', value: 'tg_50000' },
    { text: 'laut Bundesbank', value: 'tg_bb' },
  ],
  festgeldKurz: [
    { text: '3 Monate / 5.000 €', value: 'fg_5000_3' },
    { text: '6 Monate / 5.000 €', value: 'fg_5000_6' },
    { text: '12 Monate / 5.000 €', value: 'fg_5000_12' },
    { text: '3 Monate / 50.000 €', value: 'fg_50000_3' },
    { text: '6 Monate / 50.000 €', value: 'fg_50000_6' },
    { text: '12 Monate / 50.000 €', value: 'fg_50000_12' },
  ],
  festgeldSparbrief: [
    { text: '2 Jahre', value: 'sb_2' },
    { text: '3 Jahre', value: 'sb_3' },
    { text: '4 Jahre', value: 'sb_4' },
    { text: '5 Jahre', value: 'sb_5' },
    { text: '7 Jahre', value: 'sb_7' },
    { text: '10 Jahre', value: 'sb_10' },
  ],
  sparbuch: [
    { text: 'Basis- / Spareckzins', value: 'spareckzins' },
    { text: '50.000 €', value: 'spareinlage_50000' },
  ],
  hypothekenzinsen: [
    { text: '5 Jahre', value: 'bg_5' },
    { text: '10 Jahre', value: 'bg_10' },
    { text: '15 Jahre', value: 'bg_15' },
    { text: '20 Jahre', value: 'bg_20' },
    { text: 'Kfw 124', value: 'kfw_124' },
  ],
  ratenkredit: [
    { text: '36 Monate', value: 'rk_36' },
    { text: '48 Monate', value: 'rk_48' },
    { text: '60 Monate', value: 'rk_60' },
    { text: '72 Monate', value: 'rk_72' },
  ],
  giro: [
    { text: 'Dispo', value: 'dispo' },
    { text: 'Weitere Überziehung', value: 'dispo_plus' },
  ],
  euribor: [
    { text: '1 Monate', value: 'euribor_1' },
    { text: '3 Monate', value: 'euribor_3' },
    { text: '6 Monate', value: 'euribor_6' },
  ],
};

export const CHART_GENERATOR_DETAIL_FORM_HINTS = {
  split:
    'Liegen zwei Kurven sehr weit auseinander - wie Tagesgeld aund Giro Dispo -, können Sie die Skalieung ' +
    'unterbrechen, \n um detailliertere Zinsverläufe in feineren Indexabstufungen darzustellen.',
  differentialValue:
    'Visualisieren Sie den Differenzwert aus zwei Kurven: Der Wert der unteren Datenreihe wird von der oberen abgezogen.',
  annotation:
    'Weltpolitische Entscheidungen, wirtschaftliche Ereignisse, große Naturkatastrophen:\n' +
    ' Sehen Sie, welche Geschehnisse Auswirkung auf die Zinsen hatten.',
  showLogo: 'Sie können die Anzeige des Logos in der Grafik ausblenden',
};

export const SPECIAL_CHART_FORM_OPTIONS = [
  { text: 'Hypothekenzinsen ab 1980', value: 'rbGrafikAuswahl1' },
  { text: 'Bausparzinsen ab 1993', value: 'rbGrafikAuswahl2' },
  { text: 'Forward-Aufschläge ab 2010', value: 'rbGrafikAuswahl3' },
  { text: 'Goldpreis laut Bundesbank', value: 'goldpreis' },
  { text: 'Hauspreis-Entwicklung ab 2004', value: 'rbGrafikAuswahl5' },
];

export const SPECIAL_CHART_FORM_SUB_OPTIONS = {
  rbGrafikAuswahl1: [
    { text: 'Zinsbindung 5 Jahre', value: 'effektivzins_5' },
    { text: 'Zinsbindung 10 Jahre', value: 'effektivzins_10' },
    { text: 'Zinsbindung 15 Jahre', value: 'effektivzins_15' },
  ],
  rbGrafikAuswahl2: [
    { text: 'BSV-Guthabenzins', value: 'bs_guthabenszins' },
    { text: 'Tagesgeldzinsen', value: 'bs_tagesgeld_index' },
    { text: 'BSV-Darlehenzins', value: 'bs_darlehenszins' },
    { text: 'Hypothekenzins 10 Jahre', value: 'bs_baugeld_10' },
  ],
  rbGrafikAuswahl3: [
    { text: 'Aufschlag für 12 Monate', value: 'fd_aufschlag_12' },
    { text: 'Aufschlag für 24 Monate', value: 'fd_aufschlag_24' },
    { text: 'Aufschlag für 36 Monate', value: 'fd_aufschlag_36' },
    {
      text: 'Eff.Zins 10 J. fest inkl. \nForward-Aufschlag für 12 Mon.',
      value: 'fd_effektivzins_12',
    },
    {
      text: 'Eff.Zins 10 J. fest inkl. \nForward-Aufschlag für 24 Mon.',
      value: 'fd_effektivzins_24',
    },
    {
      text: 'Eff.Zins 10 J. fest inkl. \nForward-Aufschlag für 36 Mon.',
      value: 'fd_effektivzins_36',
    },
  ],
  rbGrafikAuswahl5: [
    {
      text: 'Bauzinsen und Kredithöhe',
      value: 'hp_darlehenhoehe',
      additionalValue: {
        text: 'Bauzinsen 10 Jahre fest Quartalsmittelwert in %',
        value: 'hp_hypothekenzins_index',
      },
    },
    {
      text: 'Nettoeinkommen und Kredithöhe',
      value: 'hp_einkommen',
      additionalValue: {
        text: 'Bauzinsen 10 Jahre fest Quartalsmittelwert in %',
        value: 'hp_darlehenhoehe',
      },
    },
    {
      text: 'Kredithöhe und Immobilienpreise',
      value: 'hp_preis_neubau_etw',
      additionalValue: {
        text: 'Mögliche Kredithöhe in Euro',
        value: 'hp_darlehenhoehe',
      },
      additionalValue2: {
        text: 'Durchschnittliche Neubau-Hauspreise in Euro',
        value: 'hp_preis_neubau_haus',
      },
    },
    {
      text: 'Hauspreis und Nettoeinkommen',
      value: 'hp_preis_neubau_haus',
      additionalValue: {
        text: 'Nettoeinkommen p. M. für 2 Personen',
        value: 'hp_einkommen',
      },
    },
  ],
};

export const ALL_COUNTRIES = [
  DACH_COUNTRIES,
  EUROPA_COUNTRIES,
  EU_COUNTRIES,
  BENELUX_COUNTRIES,
  BRICS_COUNTRIES,
  AFRICA_COUNTRIES,
  ASIAN_COUNTRIES,
  AUSTRALIA_AND_OCEANIA_COUNTRIES,
  AMERICAN_COUNTRIES,
].flat();
export const MONTHS = {
  0: '01',
  1: '02',
  2: '03',
  3: '04',
  4: '05',
  5: '06',
  6: '07',
  7: '08',
  8: '09',
  9: '10',
  10: '11',
  11: '12',
};
export const WETTBEWERB_CHART_OPTIONS = {
  'wettbewerb-baugeld': {
    minWidth: 2.5,
    maxWidth: 1.5,
    minColor: '#EBC100',
    maxColor: '#181818',
  },
  'wettbewerb-festgeld': {
    minWidth: 1.5,
    maxWidth: 2.5,
    minColor: '#cc0086',
    maxColor: '#ff3339',
  },
  'wettbewerb-tagesgeld': {
    minWidth: 1.5,
    maxWidth: 2.5,
    minColor: '#cc0086',
    maxColor: '#ff3339',
  },
};
export const TAGESGELD_TABLE_HEADERS_ORDER = {
  anbieter: 0,
  produkt: 1,
  produkt_zinssatz: 2,
  produkt_aktionszinssatz: 3,
  produkt_zinsgarantie: 4,
  produkt_datumZinsaenderung: 5,
  produkt_mindestanlage: 6,
  produkt_maximalanlage: 7,
  produkt_zinsgutschrift: 8,
  produkt_zinsberechnung: 9,
  einlagensicherung: 10,
};
export const FESTGELD_TABLE_HEADERS_ORDER = {
  anbieter: 0,
  produkt: 1,
  '1M_zinssatz': 2,
  '2M_zinssatz': 3,
  '3M_zinssatz': 4,
  '6M_zinssatz': 5,
  '9M_zinssatz': 6,
  '12M_zinssatz': 7,
  '18M_zinssatz': 8,
  '24M_zinssatz': 9,
  '36M_zinssatz': 10,
  '48M_zinssatz': 11,
  '60M_zinssatz': 12,
  '72M_zinssatz': 13,
  '84M_zinssatz': 14,
  '96M_zinssatz': 15,
  '108M_zinssatz': 16,
  '120M_zinssatz': 17,
  produkt_datumZinsaenderung: 18,
  produkt_mindestanlage: 19,
  produkt_maximalanlage: 20,
  produkt_zinsgutschrift: 21,
  produkt_zinsberechnung: 22,
  einlagensicherung: 23,
};
export const BACKEND_URL =
  getEnv('VUE_APP_BACKEND_URL') || 'https://staging-bp.fmh.de';
export const AUTH_API =
  getEnv('VUE_APP_AUTH_API') || 'https://staging-bp.fmh.de';
export const MATOMO_SITE_ID = getEnv('VUE_APP_MATOMO_SITE_ID') || '';
export const MATOMO_HOST = getEnv('VUE_APP_MATOMO_HOST') || '';
export const SUPERSET_BACKEND_URL =
  getEnv('VUE_APP_SUPERSET_BACKAND_URL') || 'https://superset.fmh.de';
export const HOST = getEnv('VUE_APP_HOST') || 'https://dev.bankenportal.fmh.de';
