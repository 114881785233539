import { instance } from '../../utils';
import axios from 'axios';
import router from '../../router';

export default {
  loading(state, payload) {
    state.loading = payload;
  },
  setUser(state, payload) {
    localStorage.setItem('token', payload.token);
    localStorage.setItem('hint', 'true');
    localStorage.setItem('expiresAt', JSON.stringify(payload?.expiresAt || ''));
    const permissions = payload?.permissions || [];
    const servicePlan = payload?.info?.servicePlan || [];
    const result = state.permissions
      .filter((item) => item === 'rest')
      .concat(permissions, servicePlan);

    let _paq = (window._paq = window._paq || []);
    localStorage.setItem('permissions', JSON.stringify(result));
    axios.defaults.headers.common['Authorization'] = `Token ${payload.token}`;
    instance.defaults.headers.common[
      'Authorization'
    ] = `Token ${payload.token}`;
    const { _id, email, givenName, familyName } = payload.info;
    const isDemoUser = email.includes('demo');

    localStorage.setItem(
      'user',
      JSON.stringify({ _id, email, givenName, familyName, isDemoUser })
    );
    _paq.push(['setUserId', email]);
    state.user = { _id, email, givenName, familyName, isDemoUser };
    state.isAuthorized = true;
    state.loading = false;
    state.permissions = result;
  },
  setAuthorized(state, payload) {
    const permissions = localStorage.getItem('permissions');
    const user = localStorage.getItem('user');
    if (!permissions) {
      localStorage.clear();
      return router.push('/login');
    }
    state.user = JSON.parse(user);
    state.permissions = JSON.parse(permissions);
    state.isAuthorized = payload;
  },
  setSnackBarData(state, payload) {
    state.snackbarData = {
      ...state.snackbarData,
      ...{ text: payload.text, show: payload.show, timeout: payload.timeout },
    };
  },
  error(state, payload) {
    state.error = payload;
  },
  clearStore(state) {
    state.user = null;
    state.isAuthorized = false;
    state.loading = false;
    state.permissions = ['rest'];
  },
};
