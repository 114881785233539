



































import { Component, Vue, Watch } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';

@Component({})
export default class FooterBar extends Vue {
  @Watch('$route') setLanguage() {
    if (this.$router.currentRoute.path !== '/banking-profile/add-new-report') {
      i18n.locale = 'de';
    }
  }
  get currentYear() {
    return new Date().getFullYear();
  }
}
