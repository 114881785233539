import { DEFAULT_HEADERS_BANKING_PROFILE } from '@/utils/constants';
import { defaultState } from './index';

export default {
  loading(state, payload) {
    state.loading = payload;
  },
  error(state, payload) {
    state.error = payload;
  },
  setAvailableDates(state, payload) {
    const { data, date } = payload;
    state.availableDates = data.map((item) => ({
      date: date(item.timestamp).format('YYYY-MM-DD'),
      ...item,
    }));
    state.loading = false;
  },
  setReports(state, payload) {
    state.allReports = payload;
    state.uploadedReports = true;
  },
  setBankingProfile(state, data) {
    state.bankenProfile = data;
    state.loading = false;
  },
  addNewReport(state, data) {
    const headers = DEFAULT_HEADERS_BANKING_PROFILE;
    const rows = state.bankenProfile || [];
    if (state.allReports.length) {
      state.allReports = state.allReports.concat({ ...data, headers, rows });
    }
  },
  updateAllReports(state, slug) {
    state.allReports = state.allReports.filter((item) => item.slug !== slug);
  },
  setSnackBarData(state, payload) {
    state.snackbarData = {
      ...state.snackbarData,
      ...{ text: payload.text, show: payload.show, timeout: payload.timeout },
    };
  },
  setSingleReport(state, payload) {
    const { slug, ...rest } = payload;
    state.singleReport = Object.assign(
      rest,
      { slug: slug ? slug : state.singleReport.slug },
      {}
    );
    state.loading = false;
  },
  updateReport(state, data) {
    const { field, rows, ...rest } = data;

    if (state.allReports.length) {
      const singleReport = {};
      state.allReports = state.allReports.map((item) => {
        if (item.slug === rest.slug) {
          Object.assign(singleReport, rest, {
            title: rest.title,
            note: rest.note,
            config: rest.config,
            rows,
          });
          return {
            title: rest.title,
            note: rest.note,
            config: rest.config,
            rows: item.rows,
            ...rest,
          };
        }
        return item;
      });
      state.singleReport = singleReport;
    } else {
      state.singleReport = {
        title: rest.title,
        note: rest.note,
        config: rest.config,
        rows,
        ...rest,
      };
    }
  },
  setLoadingByDate(state, payload) {
    state.isLoadingByDate = payload;
  },
  setBankDetails(state, payload) {
    const { row, ...rest } = payload;
    const defaultFields = [
      'standortlaender',
      'schwerpunkte',
      'bankratings',
      'landkuerzel',
    ];

    const fields = [
      'leverageRatio',
      'eigenkapital',
      'bilanzsumme',
      'marktkapitalisierung',
      'gesamtkapitalquote',
      'kernkapitalquote',
      'eigenkapitalrendite',
      'mitarbeiter',
      'filialen',
      'gewinn',
    ];
    const result = {
      anbietername: row.anbietername,
      id: row.id,
      defaultData: [],
      data: [],
    };
    Object.keys(row).forEach((key) => {
      if (defaultFields.includes(key)) {
        const value = row?.[key] ? row[key] : '';
        result.defaultData.push({ [key]: value });
      }
      if (fields.includes(key) && row?.[key] && row[key].length) {
        const o = {};
        Object.keys({ [key]: row[key] }).forEach((k) => {
          if (!k.includes('datum')) {
            Object.assign(o, { [k]: row[k] });
          }
        });
        Object.assign(result, {
          data: result.data.concat(o),
        });
      }
    });
    result.data.map((item) => {
      const [[key, value]] = Object.entries(item);
      return {
        [key]: value.sort((a, b) => (+a.jahr > +b.jahr ? 1 : -1)),
      };
    });

    state.bankDetails = { ...result, ...rest, rows: [row] };
    state.loading = false;
  },
  clearProfile(state) {
    state.bankenProfile = [];
  },
  resetState(state) {
    Object.assign(state, defaultState(), {});
  },
};
