const en = {
  general: {
    title: 'Title',
    note: 'Note',
    actionBtn: {
      overview: 'overview',
      resetFilters: 'reset filters',
      save: 'save',
      cancel: 'cancel',
      createReport: 'Create report',
      createEvaluation: 'Create evaluation',
      shortTerm: 'Short term',
      longTerm: 'Long term',
    },
    warning: {
      alert: 'Caution',
    },
  },
  common: {
    addNewReportCard: {
      text: 'Create a new overview',
    },
    dataIterator: {
      groupHeadline: {
        latest: 'Your latest overviews:',
        autoUpdate: 'Your current market tracks, updated daily:',
        other: 'Your saved market overviews:',
      },
      noText: 'Create your first evaluation',
    },
  },
  German: 'German',
  English: 'English',
  header: {
    bankingPortal: 'Bankingportal',
  },
  subHeader: {
    lastUpdated: 'Last updated',
  },
  footer: {
    contact: 'Contact',
    imprint: 'Imprint',
    privacy: 'Privacy',
    phone: 'Phone',
    fax: 'Fax',
  },
  sidebar: {
    actionBtn: {
      zoom: 'Zoom out',
    },
  },
  chartGenerator: {
    criteria: 'Criteria',
    tab2Text: 'Special graphs',
    colors: 'Colors',
    color: 'Color',
    specialChartHousePriceDescription: {
      hp_hypothekenzins_index_hp_darlehenhoehe:
        'The interest rates used (80% financing) are the average interest rates per quarter ' +
        '(fixed interest rate for 10 years) from the FMH database. The calculated loan amount assumes a ' +
        'financing term of 30 years with a constant interest rate.',
      hp_darlehenhoehe_hp_einkommen:
        'The net income used is based on figures from the Federal Statistical Office as of Feb. 2023. The interest ' +
        'rates used (80% financing) are the average interest rates per quarter (fixed interest rate for 10 years)' +
        ' from the FMH database. The calculated loan amount assumes a financing term of 30 years with a constant ' +
        'interest rate.',
      hp_darlehenhoehe_hp_preis_neubau_haus_hp_preis_neubau_etw:
        'The calculated loan amount assumes a financing term of 30 years with a constant interest rate. The net' +
        ' income used is based on figures from the German Federal Statistical Office as of Feb. 2023 for a dual-income ' +
        'household. The interest rates used (80% financing) are the average interest rates per quarter ' +
        '(fixed interest rate for 10 years) from the FMH database. The house and ETW prices (140 and 100 sqm)' +
        ' are based on the figures from "empirica" from December 2022 and are in each case quarterly averages for ' +
        'new buildings throughout Germany.',
      hp_einkommen_hp_preis_neubau_haus:
        'The underlying net income is based on the figures of the Federal Statistical Office from Feb. 2023 for a ' +
        'double-income household. The house prices for 140 square meters of living space are based on the figures ' +
        'from "empirica" from December 2022 and are quarterly averages for new buildings throughout Germany.',
    },
  },
  liveData: {
    generalTable: {
      noOfferTagesgeld: 'No call money offer',
      noOfferFestgeld: 'No fixed deposit offer',
    },
    title: 'Live data',
    explanations:
      'Here you can see your most recent overviews in chronological order. You can create new overviews or modify, ' +
      'export and duplicate existing ' +
      'ones. Research daily interest rates and conditions, ' +
      'save important snapshots or ' +
      'set up regular tracking of products from your most important ' +
      'competitors.',
  },
  bankingProfile: {
    title: 'Provider profiles',
    headline: 'Banking Profile',
    explanationText: 'Please enter the title and note of the report',
    selectAll: 'All',
    timePeriod: 'Time period',
    region: 'Region',
    activity: 'Activity',
    keyAreas: 'Key areas',
    filter: 'Filter',
    description: 'Brief description',
    dataFilter: 'Data filter',
    table: {
      loadingText: 'Loading items...',
      saveXLS: 'XLS Download',
      explanationText: 'Download the view as an Excel file',
      selectedAdditionalHeadersLabel: 'Show columns',
      selectColumns: 'select columns',
      closeColumnSelect: 'close column select',
      update: 'Update',
      filter: {
        comparison: 'Comparison',
        value: 'Value',
        select: 'Select',
        booleanText: 'Yes/No',
        fields: 'Fields',
        regionLabel: 'Search country',
        larger: 'Larger',
        smaller: 'Smaller',
        equal: 'Equal',
        regionSelection: 'Select country',
      },
      headers: {
        provider: 'Provider',
        locations: 'Locations',
        keyAreas: 'Business areas',
        totalCapitalRatio: 'Total capital ratio',
        returnOnEquity: 'Return on equity',
        bankRatings: 'Bank Ratings',
        headOffice: 'Head office',
        depositInsurance: 'Deposit insurance',
        totalCapitalRatioCountry: 'Total capital ratio \n Country',
        totalCapitalRatioAverage: 'Total capital ratio \n average',
        totalAssets: 'Total assets',
        foundationLocation: 'Foundation location',
        shareholder: 'Shareholder',
        subsidiaries: 'Subsidiaries',
        stockExchangeListed: 'Listed',
        employees: 'Employees',
        branchOffices: 'Branch offices',
        coreStory: 'Core story',
        mortgageVolume: 'Mortgage volume',
        coreCapitalRatio: 'Core capital ratio',
        marketCapitalization: 'Market capitalization',
        equity: 'Equity',
        leverageRatio: 'LeverageRatio',
        ratingParentCompany: 'Rating \n Parent company',
        date: 'Date',
        foundationYear: 'Founding year',
        returnEquityAverage: 'Average return on equity',
        creditVolume: 'Credit volume',
        investmentVolume: 'Investment volume',
        commissionIncome: 'Commission income',
        profit: 'Profit',
        savingsDeposits: 'Savings Deposits',
        otherliabilities: 'Otherliabilities',
        totalPayables: 'Total Payables',
        lienOnRealProperty: 'Lien on real property',
        receivablesFromBanks: 'Receivables from banks',
        receivablesCustomers: 'Receivables customers',
        commissionExpenses: 'Commission expenses',
        netCommissionIncome: 'Net commission income',
        commissionSurplus: 'Commission surplus',
      },
    },
  },
  singleReport: {
    dateSelection: {
      label:
        'You have the option to compare conditions based on two dates. \n Start by selecting the date that is' +
        ' further in the past.\n If you want to see real-time data, just click',
    },
    button: 'Load Live Data',
  },
  dashboard: {
    newHere:
      'Use our innovative SaaS solution and make your market monitoring more efficient and easier.' +
      ' Get a comprehensive overview of the latest trends and developments so that you are always well informed and ' +
      'can react quickly to changes.',
    topAnbieter: {
      tagesgeldTop5: 'Call money top 5',
      festgeldTop5: 'Fixed Deposit Top 5',
      rang: 'Rang',
      zins: 'Zins',
      competitor: 'Competitor',
      '2y': '2y',
      '5y': '5y',
      '10y': '10y',
      '3m': '3m',
      '6m': '6m',
      '12m': '12m',
      noData: 'No data available',
    },
  },
  wizard: {
    progressBar: {
      'Musterkunden erstellen': 'Create sample customers',
      'Wettbewerber auswählen': 'Select competitor',
      'Produkte filtern': 'Filter offers',
      'Angebote filtern': 'Filter offers',
      'Banken Auswahl': 'Bank selection',
      Selektion: 'Criteria',
      Felderauswahl: 'Field selection',
      Vorschau: 'Preview',
      Speichern: 'Save',
      Fertig: 'Finished',
      'Filter Auswahl': 'Filter selection',
      Spaltenauswahl: 'Column selection',
      Laufzeiten: 'Terms',
    },
  },
  warnings: {
    zinsChartWarning: 'Select at least one product',
    demoUserDeletingReport:
      'Deleting of the demo report is not allowed as a demo user',
    demoUserEditingReport:
      'Editing of the demo report is not allowed as a demo user',
  },
};

export default en;
